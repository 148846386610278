<script lang="ts">
    import { blur, fade, fly, slide } from 'svelte/transition';
    import { afterUpdate, createEventDispatcher, onMount, tick } from 'svelte';
    import { Chains, IChain } from '../../Data/Chains';
    import { SkipTexts, Locale } from '../../Data/Locale';
    import chains from '../../Data/Chains';
    import { IProviderRate } from '../../Data/P2P';
    import BigNumber from 'bignumber.js';
    import { IToken } from '../../Data/Tokens';

    //same idea as the other two lists
    //get rate items from calculateRampPrices, mod that func to return an array of all possible rates
    //only show button to pull up list if array is not empty

    export let rates: IProviderRate[];
    export let selectedAsset: IToken | null;

    const dispatch = createEventDispatcher();

    const formatValue = (value: string, decimals: number) => BigNumber(value || 0).div(10 ** (decimals || 18)).toFixed(BigNumber(value).mod(10 ** (decimals || 18)).isZero() ? 0 : 3);

    const onRateSelectClick = (rate: IProviderRate) => {
        dispatch('rateSelected', {rate: rate});
        dispatch('close');
    }
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<div on:click={() => dispatch('close')} class="w-screen h-screen bg-transparent fixed top-0 left-0"></div>

<main
    class="list-body absolute md:fixed md:min-w-[19em] max-h-[24em] overflow-y-auto z-10"
    id="_token_list_modal"
    in:blur={{ duration: 300 }}
    out:blur={{ duration: 300 }}>
    <div class="flex items-center justify-between">
        <span class="text-black font-light pr-4">{'Alternative Rates'}:</span>
        <button on:click={() => dispatch('close')}>
            <svg
                class="svg-icon"
                style="width: 1em; height: 1em;vertical-align: middle;fill: currentColor;overflow: hidden;"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                ><path
                    d="M512 451.6608L403.3536 343.0144a42.606933 42.606933 0 0 0-60.305067 0.034133 42.666667 42.666667 0 0 0-0.034133 60.305067L451.6608 512 343.0144 620.6464a42.606933 42.606933 0 0 0 0.034133 60.305067 42.666667 42.666667 0 0 0 60.305067 0.034133L512 572.3392l108.6464 108.6464a42.606933 42.606933 0 0 0 60.305067-0.034133 42.666667 42.666667 0 0 0 0.034133-60.305067L572.3392 512l108.6464-108.6464a42.606933 42.606933 0 0 0-0.034133-60.305067 42.666667 42.666667 0 0 0-60.305067-0.034133L512 451.6608zM512 1024C229.666133 1024 0 794.333867 0 512S229.666133 0 512 0s512 229.666133 512 512-229.666133 512-512 512z m0-938.666667c-235.264 0-426.666667 191.402667-426.666667 426.666667s191.402667 426.666667 426.666667 426.666667 426.666667-191.402667 426.666667-426.666667-191.402667-426.666667-426.666667-426.666667z"
                    fill="#03a9f4" />
            </svg>
        </button>
    </div>

    {#each rates as rate}
        <button
            class="w-full flex items-center pt-2 hover:bg-gray-300 rounded-md"
            on:click={() => {
                onRateSelectClick(rate);
            }}>
            <!-- <div class=" flex items-center justify-normal grow">
                <span>{+rate.spread / 100}%</span>
            </div> -->
            <div class="flex">
                <div class="font-normal text-right text-slate-700 mr-2">
                    {formatValue(String(rate.min), selectedAsset?.decimals || 18)}
                    <span class="font-semibold text-xs">{selectedAsset?.symbol} min</span>
                </div>
                <div class="font-normal text-right text-slate-700 mr-2">
                    {formatValue(String(rate.max), selectedAsset?.decimals || 18)}
                    <span class="font-semibold text-xs">{selectedAsset?.symbol} max</span>
                </div>
                <div class="font-normal text-right text-slate-700">
                    {BigNumber(rate.spread || 0).div(100)}%
                    <span class="font-semibold text-xs">{'SPREAD'}</span>
                </div>
            </div>
        </button>
    {/each}
</main>

<style lang="scss">
    .list-body {
        z-index: 1;
        border-radius: 16px;
        padding: 16px 16px 16px 16px;
        border: 1px solid var(--border-color);
        font-size: 20px;
        background: rgba(255, 255, 255);
        box-shadow:
            0px 1px 0px 0px rgba(0, 0, 0, 0.01),
            0px 8px 4px 0px rgba(0, 0, 0, 0.04),
            0px 24px 16px 0px rgba(0, 0, 0, 0.04),
            0px 32px 24px 0px rgba(0, 0, 0, 0.01);
        /* min-height: 366px; */
        border-end-end-radius: 5px;
        border-top-right-radius: 5px;
        overflow-x: hiden;
        overflow: overlay;
    }

    .token-logo {
        width: 2em;
    }
</style>
