<script lang="ts">
  import { createEventDispatcher, onDestroy, onMount, tick } from 'svelte';
  import type { IP2PTrade } from '../../Data/P2P';
  import { Wallet, Xmtp } from '../../Data/Wallet';
  import { getMessageCounts, getMessages, initXmtp, newChat, setNewMessageCountToZero } from '../../API/Chat';
    import { Conversation } from '@xmtp/xmtp-js';

  export let target: string;
  export let order: IP2PTrade;
  export let debugChat: boolean = false;
  export let messages: any[] = [];

  let chat: Conversation;
  let updateTimeout: any;
  let updateInterval = 2500;
  let chatInput: HTMLInputElement;
  let loading = false;
  let currentMessageCount = 0;

  const dispatch = createEventDispatcher();

  let msg: string;
  let ref: HTMLElement;//= this;

  onMount(async () => {
    if(!order.tradeId){
      dispatch('close');
      return;
    }
    loading = true;
    chat = await newChat(target, order.tradeId, debugChat);
    messages = await getMessages(chat);
    scrollToBottom(ref);
    loading = false;
    updateTimeout = setTimeout(updateMessages, updateInterval);

    if(screen.width > 720) chatInput.focus();

  });

  onDestroy(() => {
    clearTimeout(updateTimeout);
  });

  const updateMessages = async () => {
    console.log('updateMessages called');
    if(!chat || !ref) return;
    clearTimeout(updateTimeout);
    messages = await getMessages(chat) || messages;
    messages = [...messages];
    console.log('updateMessages messages: ', messages.length)
    console.log('updateMessages currentMessageCount: ', currentMessageCount);
    if(messages && messages?.length > currentMessageCount) scrollToBottom(ref);
    currentMessageCount = messages?.length || 0;
    updateTimeout = setTimeout(() => updateMessages(), updateInterval);
  }


  const onSendClick = async () => {
    if(!msg) return;

    const newMsg = msg;
    msg = '';
    const sentMsg = await chat.send(newMsg);
    messages.push(sentMsg);
    messages = [...messages];

    await tick();
    scrollToBottom(ref);

    getMessageCounts([order]).then(() => {
      setNewMessageCountToZero([order])
    });

  };


  const scrollToBottom = (node: any) => {
    if(!node || !messages || messages?.length == currentMessageCount) return;
    currentMessageCount = messages?.length || 0;

    const scroll = () => node.scroll({
        top: node.scrollHeight,
        behavior: 'smooth',
    });
    
    scroll();

    return { update: scroll }
};
  
</script>

<div class="flex-col justify-center items-center h-full absolute w-full z-50" >
  <main
  class="chatBg rounded-bottom shadow-lg text-black mx-auto chat-box h-full"
  >
    <div class="relative px-4 h-[87%]">
      <button class="absolute right-0 top-0 px-6 py-4 text-2xl hover:text-blue1" on:click="{() => dispatch('close')}">
        <i class="fa-regular fa-circle-xmark text-sky-500"></i>      
      </button>

      <div class="text-yellow-400 text-xs text-center chat-header border-4 chatHeaderBorder">

        <span class="text-sm">Chatting with {order.taker?.toLowerCase() === $Wallet.address.toLowerCase() ? 'Liquidity Provider' : 'Ramp Client'}:</span>
        <span class="text-xxs chatText">{order.taker?.toLowerCase() === $Wallet.address.toLowerCase() ? order.maker : order.taker}</span>
        <a target="_blank" href="https://skipscan.win/address/{order.taker?.toLowerCase() === $Wallet.address.toLowerCase() ? order.maker : order.taker}">
          <span class="text-xxs chatText">
            {"View counterparty on SkipScan"} 
            <i class="fad fa-external-link-square-alt"/>
          </span>
        </a>
        <span class="text-xxs text-emerald-500 "><i class="fad fa-lock mr-2"/>{"End-to-End Encrypted Chat"}</span>
      </div>

      {#if loading}
        <div class="max-h-[80%] overflow-y-auto pr-3 flex flex-col items-center justify-center h-full"  id="msgsBox" >
          <div class="spinner"/>
          <span class="pt-2 infoText">Loading decentralized chat messages...</span>
        </div>
      {:else}
        <div class="text-center py-2 text-xs infoText">message history:</div>
        <div class="max-h-[80%] overflow-y-auto pr-3" bind:this="{ref}" id="msgsBox" use:scrollToBottom>
          {#if messages}
            {#each messages as m}
            {#if m.senderAddress.toLowerCase() !== $Wallet.address.toLowerCase()}
            <div class="msg-timestamp infoText">{new Date(m.sent).toLocaleString()}</div>  
            <div class="msg-from">{m.content}</div>
            {/if}
            {#if m.senderAddress.toLowerCase() === $Wallet.address.toLowerCase()}
            <div class="msg-timestamp infoText">{new Date(m.sent).toLocaleString()}</div>  
            <div class="msg-to">{m.content}</div>
            {/if}
            {/each}
          {/if}
        </div>
      {/if}
    </div>
    <form
      class="relative py-2 rounded-bl-xl rounded-br-xl flex items-center"
      on:submit|preventDefault="{onSendClick}"
    >
      <div class="flex-1 h-full">
        <input
          class="msg-input w-full h-full border-0 m-0 py-2.5 px-6 rounded-3xl"
          bind:value="{msg}"
          bind:this="{chatInput}"
          placeholder="Type here..."
          tabindex="0"
        />
      </div>
      <button type="submit" class="msg-button">
        <i class="far fa-paper-plane text-sky-500"></i>
      </button>
    </form>
  </main>
</div>

<style lang="scss">
  main {
    @apply mt-2;
  }

  .chat-box {
    overflow: hidden;
  }

  .chat-header {
    @apply flex flex-col;
    width: 115%;
    margin-left: -10%;
    padding: 0.5rem;
    box-shadow: 0px 0px 5px #0f264880;
  }

  .msg-input {
    border-width: 3px;
    border-style: solid;
    margin: 0rem 1rem;
    border-color: var(--input-bg);
    color: var(--info-text)
  }
  .rounded-bottom{
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }
  .msg-button {
    @apply flex justify-center text-lg hover:text-blue-300;
    width: 70px;
  }

  .msg-timestamp {
    @apply text-xs text-center;
  }

  .msg-from {
    @apply mb-2 px-3 py-1 text-sm w-full bg-green-600 border-0  rounded-3xl  border-gray-600 text-white;
    overflow-wrap: break-word;
    max-width: fit-content;
  }

  .msg-to {
    @apply ml-auto mb-2 px-3 py-1 text-sm w-full bg-cyan-600 border-0  rounded-3xl  border-teal-600 text-white;
    overflow-wrap: break-word;
    max-width: fit-content;
  }

  .spinner {
    width: 4em;
    height: 4em;
    border: 0.5em solid rgba(0, 0, 0, 0.1);
    border-left-color: #0ea5e9;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }

  ::-webkit-scrollbar {
    width: 7px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #323232;
    border-radius: 65px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 65px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
</style>
