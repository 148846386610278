<script lang="ts">
    import { setNewMessageCountToZero } from '../../API/Chat';
    import { generateCoinSVG } from '../../API/utils/tokenImagerator';
    import BigNumber from 'bignumber.js';
    import { Locale, TradeListTexts } from '../../Data/Locale';
    import { IMessageTracker, messageTrackerStore } from '../../Data/MessageCounts';
    import { IP2PTrade, paymentMethods, IP2PTradeLiq, getPhaseToStatus, paymentMethodsMap } from '../../Data/P2P';
    import { acceptTrade, cancelTrade, completeTrade, hasCompleted, rejectTrade, requestArbitration, submitConfirmation } from '../../API/P2P';
    import { USER_SETTINGS } from '../../Data/TransactionSettings';
    import { ChainId, Wallet } from '../../Data/Wallet';
    import { createEventDispatcher, onMount } from 'svelte';
    import { addActionLogLine } from '../../Data/ActionLogs';
    import LoadBlaster from '../../components/Common/LoadBlaster.svelte';
    import { 
        getTradeAmount, 
        getShortenedId, 
        copyToClipboard, 
        getPhaseProgress, 
        getAmountTooltip, 
    } from '../../API/TradeItem';

    const dispatch = createEventDispatcher();

    export let trade: IP2PTradeLiq;
    export let tradeIndex: number;
    export let arbitrationEnabled: boolean;
    export let showChatBox: boolean = false;

    let target: string;
    let msgCount: IMessageTracker;
    let confId: string;
    let providerPayIds: string[] = [];

    let loading: boolean = false;

    let convoId: string;

    onMount(async () => {
        let tid = trade.tradeId?.toString();
        let cid = trade.asset.chainId || $ChainId;
        convoId = `skipShift.tx_${tid}_${cid}`;

        console.log('tradeItem tradeItem convoId: ', convoId);
        console.log('tradeItem tradeIndex: ', tradeIndex);
        console.log('tradeItem $messageTrackerStore: ', $messageTrackerStore);
        console.log('tradeItem $messageTrackerStore[convoId]: ', $messageTrackerStore[convoId]);
    });

    // $: $messageTrackerStore[convoId]?.newMessages && (() => {
    //     console.log('tradeItem reactive convoId: ', convoId);
    //     ($messageTrackerStore = { ...$messageTrackerStore })
    // })();

    const isMaker = (trade: IP2PTrade) => {
        return trade.maker?.toLowerCase() === $Wallet.address.toLowerCase();
    };
    const isTaker = (order: IP2PTrade) => {
        return !isMaker(order);
    };

    const onShowChat = async () => {
        // await getMessages(chat);
        trade.updateChat = true;

        showChatBox = true;
        trade.unreadMessages = 0;
        if (!trade?.tradeId) return;

        if (msgCount && Object.keys(msgCount).length > 0) {
            if (msgCount[trade?.tradeId]) msgCount[trade?.tradeId].count = msgCount[trade?.tradeId]?.lastCount;
        }

        // TODO: get msg counts for one trade only
        // getMessageCounts(tradeList, msgCount);
        target = isTaker(trade) ? trade.maker || '' : trade.taker || '';

        // TODO: decide and fix
        dispatch('openChat', { target: target, trade: trade });
        setNewMessageCountToZero([trade]);
    };

    const getVenueIcon = (trade: IP2PTrade) => {
        let match = Object.values(paymentMethods).find((method) => method.shortName === trade.venue?.platform);
        if (match) return match.logoURI;
        else return generateCoinSVG(trade.asset.symbol || '');
    };

    const updatePayIdSegmented = (val: string, i: number, total: number, tradeIndex: number) => {
        if (!providerPayIds[tradeIndex]) {
            providerPayIds[tradeIndex] = '';
        }

        let parts = providerPayIds[tradeIndex].split('::');
        while (parts.length < total) {
            parts.push('');
        }

        parts[i] = val;
        providerPayIds[tradeIndex] = parts.join('::');
        console.log('updated user pay id', providerPayIds[tradeIndex]);

        providerPayIds = [...providerPayIds];
    };

    const onCopyTransactionIdClick = async (trade: IP2PTrade) => {
        await navigator.clipboard.writeText(trade.confId || '');
        addActionLogLine('Confirmation # copied to clipboard!');
    };

    const onActionButtonClick = async (trade: IP2PTrade, tradeIndex: number) => {
        trade.confId = confId;
        console.log('trade: ', trade);
        console.log('trade.rampPhase: ', trade.rampPhase);

        if (trade.rampPhase == 0 && $Wallet.address.toLowerCase() == trade.maker?.toLowerCase()) {
            let accept: boolean;
            if (trade.tradeId && trade.asset.chainId) {
                loading = true;
                addActionLogLine('Accepting trade...')
                accept = await acceptTrade(trade, providerPayIds[tradeIndex]);
                if (accept) {
                    addActionLogLine('SkipShift trade accepted!');
                    dispatch('reload');
                }
                loading = false;
            }
        }
        if (trade.rampPhase == 2 && trade.type == 'ONRAMP') {
            if (trade.taker?.toLowerCase() == $Wallet.address.toLowerCase()) {
                console.log('submitting conf');
                let submit = await submitConfirmation(trade);
                if (submit !== false) dispatch('reload');
                return;
            }

            if (trade.maker?.toLowerCase() == $Wallet.address.toLowerCase()) {
                loading = true;
                let confirmationless_complete = await completeTrade(trade);
                if (!confirmationless_complete !== false) dispatch('reload');
                loading = false;
            }
        }
        if (trade.rampPhase == 2 && trade.type == 'OFFRAMP') {
            if (trade.maker?.toLowerCase() != $Wallet.address.toLowerCase()) return;
            console.log('submitting conf');
            let submit = await submitConfirmation(trade);
            if (submit !== false) dispatch('reload');
            return;
        }
        if (trade.rampPhase == 3) {
            console.log('completing trade');
            if (
                (trade.type == 'OFFRAMP' && $Wallet.address.toLowerCase() == trade.taker?.toLowerCase()) ||
                (trade.type == 'ONRAMP' && $Wallet.address.toLowerCase() == trade.maker?.toLowerCase())
            ) {
                let complete = await completeTrade(trade);
                if (complete !== false) dispatch('reload');
            }
            return;
        }
    };

    const onArbitrationClick = async (trade: IP2PTrade) => {
        await requestArbitration(trade);
    };

    const onCancelClick = async (trade: IP2PTrade) => {
        let cancel: boolean = false;
        loading = true;
        if (trade.rampPhase == 0 && trade.maker?.toLowerCase() == $Wallet.address.toLowerCase()) {
            console.log('rejecting trade');
            addActionLogLine('Rejecting P2P Trade...')
            cancel = await rejectTrade(trade).catch((e) => false);
            dispatch('reload');
        } else {
            console.log('cancelling trade');
            addActionLogLine('Cancelling P2P Trade...')
            cancel = await cancelTrade(trade).catch((e) => false);
            dispatch('reload');
        }
        loading = false;
        if (!cancel) return;
        addActionLogLine('P2P Trade Cancelled/Rejected')
    };

    const getActionTitle = (trade: IP2PTrade) => {
        if (trade.rampPhase == 0 && $Wallet.address.toLowerCase() != trade.maker?.toLowerCase()) return 'Waiting...';
        if (trade.rampPhase == 0 && $Wallet.address.toLowerCase() == trade.maker?.toLowerCase()) return 'Accept Trade';
        if (trade.rampPhase == 1) return 'Trade Rejected';
        if (trade.rampPhase == 2 && $Wallet.address.toLowerCase() == trade.taker?.toLowerCase()) return 'Conf. #';
        if (trade.rampPhase == 2 && $Wallet.address.toLowerCase() == trade.maker?.toLowerCase()) return 'Complete';
        if (trade.rampPhase == 3 && trade.type == 'OFFRAMP') return 'Complete';
        if (trade.rampPhase == 3 && trade.type == 'ONRAMP' && $Wallet.address.toLowerCase() != trade.maker?.toLowerCase()) return 'Waiting...';
        if (trade.rampPhase == 3 && trade.type == 'ONRAMP' && $Wallet.address.toLowerCase() == trade.maker?.toLowerCase()) return 'Complete';
        if (trade.rampPhase == 4) return 'Trade Complete';
        if (trade.rampPhase == 5) return 'Cancelled';

        return 'Waiting...';
    };
</script>

<div
    class="trade-item flex flex-col mb-4 {trade.maker?.toLowerCase() == $Wallet.address.toLowerCase() ? 'border-sky-500 border-4' : ''}"
    class:hidden={(!$USER_SETTINGS.showInactive && hasCompleted(Number(trade.rampPhase))) || ($USER_SETTINGS.showInactive && !hasCompleted(Number(trade.rampPhase)))}>
    <div class="flex items-center justify-between">
        <div class="flex">
            <img src="/icons/arrow-down.svg" class="width-[20px] pr-2" alt="arrow down" />
            <div class="">
                <div class="large-spacing-tooltip" data-tooltip={getAmountTooltip(trade)}>
                    <div class="font-normal infoText">
                        {getTradeAmount(trade, "top")}
                        {trade.type == 'ONRAMP' ? trade.currency?.symbol : trade.asset?.symbol}
                    </div>
                    <div class="font-normal infoText">
                        {getTradeAmount(trade, 'bottom')}
                        {trade.type == 'ONRAMP' ? trade.asset.symbol : trade.currency?.symbol}
                    </div>
                </div>
            </div>
        </div>
        <div class="venue-container flex flex-col items-center px-2 mr-2 pt-1">
            <img class="venue-icon" src={getVenueIcon(trade)} alt="venue icon" />
            <span class="font-normal text-center text-xs tradeItemVenueLabel">{trade.venue?.platform}</span>
        </div>
        <div class=" ">
            <div class="text-amber-600 text-center">Status:</div>
            <div class="font-normal infoText text-center">{getPhaseToStatus(trade.rampPhase || 0)}</div>
            <div class="progress-bar">
                <div class="progress-bar-fill" style="width: {getPhaseProgress(trade)}%"></div>
            </div>
        </div>
    </div>
    <div class="flex items-center justify-between py-1 px-2 my-1">
        {#if trade.rampPhase == 0 && trade.taker?.toLowerCase() == $Wallet.address.toLowerCase()}
            <div class="flex w-full justify-stretch py-1 px-2 rounded-lg text-sm bg-white soft-border">
                <div class="flex flex-col gap-1 grow">
                    <span class="infoText">{TradeListTexts[$Locale].onramp_init_user_message_1}</span>
                    <span class="infoText"
                        >{TradeListTexts[$Locale].onramp_init_user_message_2}{trade.venue?.platform}{TradeListTexts[$Locale].onramp_init_user_message_3}</span>
                </div>
            </div>
        {/if}
        {#if trade.rampPhase == 2 && trade.type == 'ONRAMP' && trade.taker?.toLowerCase() == $Wallet.address.toLowerCase()}
            <div class="flex w-full justify-stretch py-1 px-2 rounded-lg text-sm bg-white soft-border">
                <div class="flex flex-col gap-1 grow">
                    <span class="infoText"
                        >{TradeListTexts[$Locale].send}
                        {BigNumber(trade.totalCost || 0).toFixed(2)}
                        {trade.currency?.symbol}
                        {TradeListTexts[$Locale].on}
                        {trade.venue?.platform}
                        {TradeListTexts[$Locale].onramp_submitconf_user_message_1}
                        <span class="font-normal infoText">
                            {trade.venue?.makerId?.slice(0, 15) + ((trade.venue?.makerId?.length || 0) > 15 ? '...' : '')}
                            <button on:click={(_) => copyToClipboard(trade.venue?.makerId || '')}>
                                <i class="text-sky-500 fa-solid fa-copy text-xs" />
                            </button>
                        </span>
                    </span>
                    <span class="infoText">{TradeListTexts[$Locale].onramp_submitconf_user_message_2} </span>
                </div>
            </div>
        {/if}
        {#if trade.rampPhase == 3 && trade.confId}
            <div class="flex w-full justify-stretch py-1 px-2 rounded-full bg-white">
                <div class="flex gap-1 grow">
                    <span class="text-sky-600">TX #ID</span>
                    <span class="font-normal infoText">
                        {trade.confId?.slice(0, 9) + '...'}
                        <button on:click={(_) => onCopyTransactionIdClick(trade)}>
                            <i class="text-sky-500 fa-solid fa-copy text-xs" />
                        </button>
                    </span>
                </div>
            </div>
        {/if}
    </div>
    <div class="flex justify-between items-center action-section">
        <!--ARBITRATION BUTTON-->
        {#if arbitrationEnabled}
            <button on:click={(_) => onArbitrationClick(trade)} class="arbitration-button">
                <span class="text-white">{'Initiate arbitration'}</span>
            </button>
        {/if}
        <!--MAKER ACCEPTANCE ID INPUT-->
        {#if trade.rampPhase == 0 && $Wallet.address.toLowerCase() == trade.maker?.toLowerCase()}
            {#if trade.venue?.takerId}
                <div class="flex flex-col mr-4 md:mr-6">
                    <span class="text-sky-600">Peer ID:</span>
                    <div class="flex flex-col">
                        {#if paymentMethodsMap[trade.venue?.platform || ''].idInputFields?.length > 0}
                            {#each paymentMethodsMap[trade.venue?.platform || ''].idInputFields as placeholder, i}
                                <span class="flex infoText" data-tooltip={trade.venue?.takerId.split('::')[i]}>
                                    {getShortenedId(trade, i)}
                                    <button class="pl-2" on:click={(_) => copyToClipboard(trade.venue?.takerId || '')}>
                                        <i class="text-sky-500 fa-solid fa-copy text-xs" />
                                    </button>
                                </span>
                            {/each}
                        {:else}
                            <div class="flex">
                                <span class="infoText" data-tooltip={trade.venue?.takerId}>
                                    {getShortenedId(trade)}
                                </span>
                                <button on:click={(_) => copyToClipboard(trade.venue?.takerId || '')}>
                                    <i class="text-sky-500 fa-solid fa-copy text-xs" />
                                </button>
                            </div>
                        {/if}
                    </div>
                </div>
            {/if}

            {#if paymentMethodsMap[trade.venue?.platform || ''].idInputFields?.length > 0}
                <div class="relative mr-4">
                    {#each paymentMethodsMap[trade.venue?.platform || ''].idInputFields as placeholder, i}
                        <div class="flex flex-col">
                            <input
                                on:input={(e) =>
                                    updatePayIdSegmented(e.currentTarget?.value, i, paymentMethodsMap[trade.venue?.platform || ''].idInputFields.length, tradeIndex)}
                                    type="text"
                                    id="conf_id"
                                    class="text-black border-1 my-1 border-sky-500 rounded-lg w-[8em] md:w-[12em]"
                                    {placeholder} 
                                />
                        </div>
                    {/each}
                    {#if loading}
                        <div class="absolute right-0 top-0 mt-1 mr-1">
                            <LoadBlaster showLoad={true} color={"0ea5e9"}/>
                        </div>
                    {:else}
                        <button id="accept_trade" on:click={(_) => onActionButtonClick(trade, tradeIndex)} class="absolute right-0 top-0 mt-1 mr-1">
                            <!-- <i class="pl-2 pr-2 fa-solid fa-paper-plane text-sky-500" /> -->
                            <i class="accept-button pl-2 pr-2 fad text-xl fa-play-circle" style="--fa-primary-color: #0ea5e9; --fa-secondary-color: #96d3ee;"></i>
                        </button>
                    {/if}
                </div>
            {:else}
                <div class="relative mr-4">
                    <input
                        bind:value={providerPayIds[tradeIndex]}
                        type="text"
                        id="conf_id"
                        class="text-black border-1 border-sky-500 rounded-lg w-[8em] md:w-[12em]"
                        placeholder={trade.venue?.platform + ' ID'} />
                    {#if loading}
                        <div class="absolute right-0 top-1 mt-1 mr-1">
                            <LoadBlaster showLoad={true} color={"0ea5e9"}/>
                        </div>
                    {:else}
                        <button id="accept_trade" on:click={(_) => onActionButtonClick(trade, tradeIndex)} class="absolute right-0 top-0 mt-1 mr-1">
                            <!-- <i class="pl-2 pr-2 fa-solid fa-paper-plane text-sky-500" /> -->
                            <i class="accept-button pl-2 pr-2 fad text-xl fa-play-circle" style="--fa-primary-color: #0ea5e9; --fa-secondary-color: #96d3ee;"></i>
                        </button>
                    {/if}
                </div>
            {/if}
        {/if}
        <!--COMPLETE TRADE BUTTON-->
        <button
            id="complete_trade"
            on:click={(_) => onActionButtonClick(trade, tradeIndex)}
            class="action-button shake-on-hover mr-2 buttonGradient"
            class:hidden={!(trade.rampPhase == 3 || (trade.rampPhase == 2 && $Wallet.address.toLowerCase() == trade.maker?.toLowerCase()))}>
            {#if loading}
                <LoadBlaster showLoad={true} color="ffffff"/>
            {:else}
                <span class="text-white">{getActionTitle(trade)}</span>
            {/if}        
        </button>
        <!--CANCEL/REJECT BUTTON-->
        {#if ['0', '2', '3', '6', '7'].includes(`${trade.rampPhase}`)}
            <button id="trash" class="pr-4" on:click={(_) => onCancelClick(trade)}>
                <i class="fa-solid fa-trash text-red-600" />
            </button>
        {/if}
        {#if !['0', '2', '3', '6', '7'].includes(`${trade.rampPhase}`)}
            <a class="pr-4 no-tooltip-border" href="https://skipscan.win/trade/{trade.asset.chainId}-{trade.tradeId}" target="_blank" id="skipscan_trade" >
                <img alt="" src="/icons/skipscan.svg" class="bg-white rounded-lg p-2 w-10"/>
            </a>
        {/if}
        <!--POST ACCEPTANCE MAKER/TAKER ID READONLY-->
        {#if trade.rampPhase != 0 && trade.venue?.makerId}
            <div class="flex gap-1 pr-4">
                {#if trade.venue?.platform && paymentMethodsMap[trade.venue.platform].idInputFields.length > 0}
                    <div class="flex flex-col">
                        {#each paymentMethodsMap[trade.venue.platform].idInputFields as field, i}
                            <div on:keypress={(e) => {}}>
                                <span class="text-sky-600">{field}:</span>
                                <span
                                    data-tooltip={($Wallet.address.toLowerCase() == trade.maker?.toLowerCase()
                                        ? trade.venue?.takerId
                                        : trade.venue?.makerId
                                    )?.split('::')[i]}
                                    class="font-normal infoText">
                                    {getShortenedId(trade, i)}
                                </span>
                                <button
                                    on:click={(_) =>
                                        copyToClipboard(
                                            ($Wallet.address.toLowerCase() == trade.maker?.toLowerCase() ? trade.venue?.takerId : trade.venue?.makerId)?.split(
                                                '::'
                                            )[i] || ''
                                        )}>
                                    <i class="text-sky-500 fa-solid fa-copy text-xs" />
                                </button>
                            </div>
                        {/each}
                    </div>
                {:else}
                    <div on:keypress={(e) => {}}>
                        <span class="tradeListText">Peer:</span>
                        <span
                            data-tooltip={($Wallet.address.toLowerCase() == trade.maker?.toLowerCase() ? trade.venue?.takerId : trade.venue?.makerId) +
                                '\n(click to copy)'}
                            class="font-normal infoText">
                            {getShortenedId(trade)}
                        </span>
                        <button
                            on:click={(_) =>
                                copyToClipboard(
                                    ($Wallet.address.toLowerCase() == trade.maker?.toLowerCase() ? trade.venue?.takerId : trade.venue?.makerId) || ''
                                )}>
                            <i class="tradeListText fa-solid fa-copy text-xs" />
                        </button>
                    </div>
                {/if}
            </div>
        {/if}

        <button class="font-normal max-w-fit flex items-center shake-button" on:click={onShowChat}>
            {#if (!$messageTrackerStore[convoId] && !$messageTrackerStore[convoId]?.newMessages) || $messageTrackerStore[convoId]?.newMessages == 0}
                <i class="fad fa-comment-lines pr-1 text-2xl tradeListText" />
            {:else}
            <i
                    class="fad fa-comment-exclamation pr-1 text-2xl text-red-500 bounce-button"
                    style="--fa-primary-color: #ff0000; --fa-secondary-color: #ff0000;" />
            {/if}
        </button>
    </div>

    <!--KYP FIELDS-->
    {#if $Wallet.address.toLowerCase() == trade.maker?.toLowerCase() && trade.kypInfo?.length}
        {#if trade.kypInfo && trade.kypInfo?.length > 2}
            <div class="flex mt-2 justify-center">
                <span class="text-sky-500">{'KYP Details:'}</span>
            </div>
            <div class="flex justify-center">
                {#each trade.kypInfo.split('::') as kypInfo, index}
                    <div class="flex mr-4">
                        <span class="text-sky-500 text-xs">{trade.kypFields?.split('::')[index]}::</span>
                        <span class="test-black text-xs">{kypInfo}</span>
                    </div>
                {/each}
            </div>
        {/if}
    {/if}
</div>

<style lang="scss">
    @keyframes bounce {
        0%,
        100% {
            transform: translateY(-25%) scale(1.1);
            animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
        }
        50% {
            transform: translateY(0) scale(0.9);
            animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
        }
    }

    @keyframes tiny-bounce {
        0%,
        100% {
            transform: translateY(-15%);
            animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
        }
        50% {
            transform: translateY(0);
            animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
        }
    }
    // .pending-holder {
    //     display: flex;
    //     flex-direction: column;
    //     height: 90%;
    //     align-items: center;
    //     justify-content: center;
    //     width: 100%;
    // }
    // .switchbox-highlight {
    //     border-left-width: 10px;
    //     border-color: var(--border-color);
    //     border-style: double;
    // }
    .action-section {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    .action-button {
        border-radius: 8px;
        padding: 5px 10px;
        font-size: 0.85rem;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
        border: 2px rgb(70 126 239 / 10%) solid;
        animation: tiny-bounce 1s infinite;
    }

    // .action-button:hover {
    //     transform: scale(1.05);
    // }
    .arbitration-button {
        border-radius: 8px;
        background: linear-gradient(90deg, #ffa500 0%, rgba(255, 165, 0, 0.75) 100%);
        padding: 10px 16px;
    }
    // .loading-text {
    //     color: rgba(0, 0, 0, 0.4);
    //     /* font-family: Inter; */
    //     font-size: 20px;
    //     font-style: normal;
    //     font-weight: 600;
    //     line-height: 24px; /* 120% */
    // }
    .venue-icon {
        width: 2em;
    }
    .trade-item {
        width: 95%;
        padding: 16px;
        border-radius: 8px;
        background: var(--input-bg);
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
        border: 1px #0000001f solid;
    }
    .venue-container {
        border-radius: 8px;
        background: #fff;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
    }

    // .spinner {
    //     width: 4em;
    //     height: 4em;
    //     border: 0.5em solid rgba(0, 0, 0, 0.1);
    //     border-left-color: #0ea5e9;
    //     border-radius: 50%;
    //     animation: spin 1s linear infinite;
    // }

    .progress-bar {
        width: 100%;
        height: 10px;
        background-color: #bbb8b8; /* This is the gray background */
        border-radius: 5px;
    }

    .progress-bar-fill {
        height: 10px;
        background-color: #4caf50; /* This is the color of the filled part */
        border-radius: 5px;
    }

    @keyframes spin {
        to {
            transform: rotate(360deg);
        }
    }

    @keyframes shake {
        0% {
            transform: translateX(0);
        }
        25% {
            transform: translateX(-5.5px);
        }
        50% {
            transform: translateX(10.5px);
        }
        75% {
            transform: translateX(-15.5px);
        }
        100% {
            transform: translateX(0);
        }
    }

    .accept-button {
        font-size: 1.5rem;
        line-height: 1.65rem;
    }
    .accept-button:hover {
        scale: 1.1;
        text-shadow: 0px 0px 3px deepskyblue;
        border-radius: 100%;
        // padding: 0px;
        // font-size: 1.5rem;
        // line-height: 1.65rem;
    }

    .shake-on-hover:hover {
        animation: shake 0.25s;
    }

    .bounce-button {
        animation: bounce 1s infinite;
    }
</style>