import { IProviderRate } from "../../Data/P2P";
import { getAddressInfo } from "../../API/P2P";
import BigNumber from "bignumber.js";
import type { IToken } from "../../Data/Tokens";
import { fetchProviderHours } from "./fetchProviderHours";

/**
 * 
 * @description given a list of IProviderRates, return the IProviderRate(s) with the lowest tradeLocked
 */
export const tradeLockedFilter = async (rateList: IProviderRate[], chainId: number, pickOne = false): Promise<IProviderRate[]> => {
    console.log('rateList pre filter', rateList);
    let rateListFiltered_tl: IProviderRate[] = []
    if (rateList.length > 1) {
        try {//skip comparisons if anything fucks up
            let minTradeLocked = Infinity;
            for (let i = 0; i < rateList.length; i++) {
                let tradeLocked = (await getAddressInfo(rateList[i].maker, chainId))?.tradeLocked;
                if(rateList[i].maker == "test") tradeLocked = "3";
                if (tradeLocked) {
                    if (+tradeLocked < minTradeLocked) {
                        minTradeLocked = +tradeLocked;
                        rateListFiltered_tl = [rateList[i]];
                    } else if (+tradeLocked === minTradeLocked) {
                        rateListFiltered_tl.push(rateList[i]);
                    }
                }
            }
        } catch (e) {
            console.error('calculateRampPrices: tradeLocked comparison failed');
            rateListFiltered_tl = [];
        }
    }else{
        return rateList;
    }

    return pickOne ? [rateListFiltered_tl[0]] : rateListFiltered_tl;//pickOne == lowest trade locked, oldest listing
}

/**
 * @description given a list of IProviderRates and a rate, 
 * filter out any IProviderRates that are outside of the current time range
 */
export const timeRangeFilter = async (rateList: IProviderRate[], chainId: number, pickOne = false): Promise<IProviderRate[]> => {
    let rateListFiltered_tr: IProviderRate[] = [];
    console.log('ratelist in filter', rateList);
    try{
        for(let i = 0; i < rateList.length; i++){
            console.log('filter on loop', i);
            let range = await fetchProviderHours(rateList[i].maker, false) as Date[] || undefined;
            
            if (rateList[i].maker == 'test') range = [(new Date(new Date().setHours(3, 0, 0, 0))), new Date((new Date().setHours(4, 0, 0, 0)))]
            
            const currHours = new Date().getHours();
            const currMinutes = new Date().getMinutes();
            const startHours = range[0].getHours();
            const startMinutes = range[0].getMinutes();
            const endHours = range[1].getHours();
            const endMinutes = range[1].getMinutes();

            // Ensure the current time is within the range
            const isAfterStart = (currHours > startHours) || (currHours === startHours && currMinutes >= startMinutes);
            const isBeforeEnd = (currHours < endHours) || (currHours === endHours && currMinutes <= endMinutes);

            console.log('filter isAfterStart', isAfterStart);
            console.log('filter isBeforeEnd', isBeforeEnd);

            if (isAfterStart && isBeforeEnd) {
                rateListFiltered_tr.push(rateList[i]);
            }
        }
    }catch(e){
        console.error(e);
        rateListFiltered_tr = [];
    }

    return pickOne ? [rateListFiltered_tr[0]] : rateListFiltered_tr;
}

/**
 * @description given a list of IProviderRates and a rate, 
 * filter out any IProviderRates that do not fit the min/max and then return the IProviderRate(s) with the lowest spread.
 * TODO: the min/max filtering in this function is redundant, trades that are outside of the range will not be sent to this function to begin with
 */
export const spreadMinMaxFilter = async (rateList: IProviderRate[], chainId: number, asset: IToken, rate: BigNumber, pickOne = false): Promise<IProviderRate[]> => {
    //spread comparison for any remaining rates
    let finalRate: IProviderRate = rateList[0];
    let tiedRates: IProviderRate[] = [finalRate];

    try{
        if (!asset.decimals) return [];

        for (let i = 0; i < rateList.length; i++) {
            const tempRate = rateList[i];

            if (tempRate.max.div(10 ** asset.decimals).lt(rate) || tempRate.min.div(10 ** asset.decimals).gt(rate)) {
                continue;
            }

            if (!finalRate) {
                finalRate = tempRate;
                tiedRates = [finalRate];
                continue;
            }

            if (BigNumber(finalRate.spread).eq(tempRate.spread)) {
                tiedRates.push(tempRate);
            } else if (BigNumber(finalRate.spread).gt(tempRate.spread)) {
                finalRate = tempRate;
                tiedRates = [finalRate];
            }
        }

        console.log('tied rates', tiedRates);
        console.log('final rate', finalRate);
    }catch(e){
        console.error(e);
    }

    if (tiedRates.length > 1) {
        console.log('found ', tiedRates.length, 'tied rampRates in final filter')
        const rand = Math.floor(Math.random() * tiedRates.length);
        console.log('picking random index in final filter:', rand, "maker:",  tiedRates[rand].maker);
        finalRate = tiedRates[rand];
    }

    return pickOne ? [finalRate] : tiedRates;
}