<script>
    import IntegrateButton from "../Common/IntegrateButton.svelte";
    import SkipShift from "../SkipShift/SkipShift.svelte";
    import { PageTexts, Locale } from "../../Data/Locale";
    
    const params = new URLSearchParams(window.location.search);
    const fuckyc = params.get('fuckyc') ? true : false;
    
</script>
<div
class="home max-w-[100%]"
>
  <div class="flex flex-col flex-1 ">
    <h1 class="text-4xl md:text-7xl text-right font-semibold mb-6">
      <div class="pb-5">{ fuckyc ? 'Skip the glow.' : PageTexts[$Locale].skip_the_questions}</div> 
      <div>{PageTexts[$Locale].shift_control}</div> 
    </h1>
    <h2 class="text-lg leading-relaxed mb-8 md:max-w-[85%] m-auto mr-0">
      <!-- SkipShift let's you swap fiat to crypto to fiat and back, as often as you'd like, as much as you'd like, and from anywhere you'd like.<br> -->


      {PageTexts[$Locale].homepage_top_desc}<br><br>
      {PageTexts[$Locale].homepage_bottom_desc}<br />
      
      <!-- Experience what frictionless decentralization feels like. -->
      <!-- Welcome to SkipShift, the future of decentralized finance. Our platform stands as a beacon of innovation, 
      offering an automated, peer-to-peer gateway that bridges the gap between fiat and cryptocurrency.  -->
      <!-- Experience a world without boundaries, where transactions are not just seamless but also free from centralized 
      controls. Dive into the realm of 100% decentralization with SkipShift. -->
    </h2>
    <!-- <div class="grid grid-cols-2 gap-4 md:flex">
      <IntegrateButton></IntegrateButton>
    </div> -->
  </div>
  <div class="skip-widget">
    <SkipShift/>
    <!-- <img src="images/home.svg"/> -->
  </div>
</div>

<style lang="scss">
  .skip-widget{
    @apply flex-1 flex justify-center py-8 md:py-0;
  }
  .home {
    @apply px-4 py-16 container md:mt-16 mt-8 mx-auto flex-1 flex flex-col md:flex-row items-center justify-between
  }
</style>