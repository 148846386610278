declare global {
  interface Navigator {
      userLanguage?: string;
  }
}

import './main.css';
import App from './App.svelte';

import * as Sentry from "@sentry/svelte";


    

Sentry.init({
  dsn: "https://22e5d9b6d84145c2d0cca144c30d5971@o4507710437916672.ingest.de.sentry.io/4507710443946064",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/skipscan\.win/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// if ( window.location.hostname.search('skipshift') >= 0) {
//   // console.log = function no_console() {};
//   console.log = () => {};
//   console.warn = () => {};
//   console.error = () => {};
// }
const showConsole = window.location.href.search('showconsole') > 0;
console.log('showConsole: ', showConsole);
const inWidget = window.location.href.search('skipwidget') > 0;
if (inWidget) {//disable logs// if (  !showConsole) {
  // console.log = function no_console() {};
  console.log = () => {};
  console.warn = () => {};
  console.error = () => {};
}


const app = new App({
  target: document.body,
});

export default app;