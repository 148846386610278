<script lang="ts">
    import { registerWalletLink, signTnC } from '../../API/P2P';
    import { Wallet, WalletSignature } from '../../Data/Wallet';
    import { createEventDispatcher } from 'svelte';
    import { WalletConnected } from '../../Data/Wallet';
    import { addActionLogLine } from '../../Data/ActionLogs';
    import { connect } from '../../API/Wallet';

    const dispatch = createEventDispatcher();

    let socialId = '';
    let selectedPlatform: any[] = [];
    let platforms = ['Telegram', 'Discord'];
    let type = '';

    // https://t.me/skipshiftnotifier_bot?start=0x5DaAE1F5cb1F808373E0b94773c2d7a744218a15

    const onStartReg = async () => {
        if (!$WalletConnected) {
            try {
                await connect();
            } catch {
                addActionLogLine('You must connect your wallet to register for alerts.');
                return;
            }
        }

        var anchor = document.createElement('a');
        anchor.href = 'https://t.me/skipshiftnotifier_bot?start=' + $Wallet.address;
        anchor.target = '_blank';
        anchor.click();
    };
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->

<!-- svelte-ignore a11y-click-events-have-key-events -->
<div class="fixed inset-0 z-30 flex flex-col justify-center items-center" on:click={(_) => /*helpModal = false*/ null}>
    <div class="modalBg text-black px-4 pb-3 pt-1 rounded-3xl md:w-96 neu-border z-[31]">
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <h2>Sign up for Direct Alerts!</h2>
        <p class="infoText text-center px-4 py-8">
            Click the blue button below to be taken directly to our Telegram bot, then click "Start" and you'll be set!<br />
        </p>

        <div class="flex gap-8 justify-center my-4">
            <div class="flex justify-center gap-3 md:gap-4 w-1/3">
                <button class="collat rounded-4xl px-4 py-1 border border-white text-white" color="black" on:click={(_) => dispatch('close')}>Close</button>
            </div>

            <div class="w-1/3 justify-center">
                <button
                    type="submit"
                    class="collat bg-sky-500 text-gray-300 p-1 px-2 rounded-2xl font-semibold"
                    style="--tw-bg-opacity: 1;
            background-color: rgb(14 165 233 / var(--tw-bg-opacity));
            border-color: var(--border-color);"
                    on:click={onStartReg}>
                    <i class="fad fa-check-double"></i>
                    let's go!
                </button>
            </div>
        </div>
    </div>
    <div on:click={() => dispatch('close')} class="w-screen h-screen bg-transparent fixed top-0 left-0"></div>
</div>

<style lang="scss">
    h2 {
        @apply text-lg text-sky-400 font-semibold text-center;
    }

    .neu-border {
        border-width: 1px;
        border-style: solid;
        border-bottom-left-radius: 2rem;
        border-top-left-radius: 1rem;
        border-top-right-radius: 2rem;
        border-bottom-right-radius: 0rem;
        overflow: hidden;
        border-block-color: darkgrey;
        border-block-style: dotted;
    }

    .collat {
        background: #171717;
        padding: 0 0.5rem;
        color: white;
        border-radius: 2rem;
        border-style: solid;
        border-width: 0.2rem;
        border-color: var(--border-color);
        width: 100%;
    }
</style>
