const CustomizationList = [
    'gempad',
];

export const setCustomTheme = () => {
    let found = false;
    for (let i = 0; i < CustomizationList.length; i++) {
        if (window.parent.location.hostname.search(CustomizationList[i]) > -1) {
            document.body.parentElement?.setAttribute('data-theme', CustomizationList[i]);
            found = true;
            break;
        }
    }

    if(!found) {
        const htmlElement = document.body.parentElement;
        const currentTheme = htmlElement?.getAttribute('data-theme') || 
                            (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light');

        
        htmlElement?.setAttribute('data-theme', currentTheme);
    }
};
