declare const ethereum: any;

import BigNumber from 'bignumber.js';
import { Wallet, Referrer, ChainId, WalletSignature } from '../Data/Wallet';
import type { IWallet } from '../Data/Wallet';
import chains, { Chains } from '../Data/Chains';
import type { IChain } from '../Data/Chains';
import { web } from '../Data/Web3Store';
import { get } from 'svelte/store';
import { getTokenInfo } from '../Data/Tokens';
import type { IToken } from '../Data/Tokens';
import { SkipShiftContracts, currencies, SubGraphs, SkipShiftStartBlocks, LastScannedTimestamp } from '../Data/P2P';
import type {
    IProviderRate,
    IP2PTradeLiq,
    IUserInfo,
    IProviderAssets,
    IP2PLiquidityLite,
    IEventAsset,
    IRawListing,
    IP2PTrade,
    IRampDetails,
    ICollatPreFetch,
    IP2PLiquidity,
    IP2PVenue
} from '../Data/P2P';
import ABI from '../Data/ABI';
import Contracts from '../Data/Contracts';
import { addActionLogLine } from '../Data/ActionLogs';
import type { ITransactionOptions } from '../Data/TransactionOptions';
import { TransactionOptions } from '../Data/TransactionOptions';
import { getPriceQuote } from './QuoteEngine';
import type { SkipShiftCore } from '../Typings/skipshift/contracts';
import { checkApproval } from './QuoteEngine';
import { DIVISOR, PAGE_SIZE, SPREAD_LIMIT, approveAmount } from '../Data/Constants';
import type { ERC20 } from '../Typings/@openzeppelin/contracts/token/ERC20';
import { submitEncryptedPayload, buildPayload, getLoadz, XMTPLoadingProgress, XMTPFragmentData } from './Chat';
import { AllLiquidityStore, Collateral, P2PCache } from '../Data/P2PStore';
import { logChunker } from './helpers/logChunker';
import { messageSign } from '../Data/TnC_Message';
import axios from 'axios';
import type { AxiosRequestConfig } from 'axios';
import { P2PAssets } from '../Data/P2PAssets';
import LogsDecoder from 'logs-decoder';
import { toChecksumAddress } from './helpers/utils';
import type Web3 from 'web3';
import { CurrencyRates } from '../Data/CurrencyRates';
import type { IQuoteResult } from '../Data/QuoteResult';
import { rpcLooper } from './helpers/rpcLooper';
import { P2PDataStore } from '../Data/P2PDataStore';
import type { SkipShiftData } from '../Data/P2PDataStore';
import { TxError } from '../Data/TxError';
import { getTokenLogo } from './helpers/trustWallet';
import { Result, aggregateMinMax } from './utils/aggregateMinMax';
import { getGasPrice, getQuoteOut } from './multiQuoter';
import { toHex } from 'web3-utils';
import { getRate } from './helpers/getCurrencyConversionRate';
import { ActionLogTexts, Locale } from '../Data/Locale';
import { verifyActive } from './helpers/verifyActive';
import { fetchAddressListings } from './helpers/fetchAddressListings';
import { trackEvent } from './analytics';
import { spreadMinMaxFilter, timeRangeFilter, tradeLockedFilter } from './helpers/rampRateFilters';
import { Ancillaries } from '../Typings/skipshift/contracts/SkipShiftCore';
import { PrefillData } from '../Data/Prefill';
import { push } from 'svelte-spa-router';
import { DEXPreload, showDEXModal } from '../Data/DEXPreload';

BigNumber.set({ DECIMAL_PLACES: 18, EXPONENTIAL_AT: 100 });

export const signTnC = async () => {
    const wallet = get(Wallet);
    const web3 = get(web);

    const signature = await web3.eth.personal.sign(messageSign, wallet.address, '');
    if (!signature) return;

    const recover = await web3.eth.personal.ecRecover(messageSign, signature);
    WalletSignature.set(signature);

    if (wallet.address.toLowerCase() === recover.toLowerCase()) {
        trackEvent('p2p', 'sign', 'success', wallet.address);

        return signature;
    } else return;
};

export const registerWalletLink = async (uid: string, platform: string) => {
    const wallet = get(Wallet).address;
    const walletSig = get(WalletSignature);

    const data = {
        uid: uid,
        platform: platform.toLowerCase(),
        walletAddress: wallet,
        walletSig: walletSig
    };

    return await axios.post(`https://t.me/skipshiftnotifier_bot?start=${get(Wallet).address}`, data);
};

export function ascii_to_hexa(str: string) {
    var arr1 = [];
    for (var n = 0, l = str.length; n < l; n++) {
        var hex = Number(str.charCodeAt(n)).toString(16);
        arr1.push(hex);
    }
    return arr1.join('');
}

export function hexToUtf8(hex: any) {
    if (hex === '0x00') return ''; //null character is non printable, must return an empty string here

    const hexString = hex.toString().replace(/^0x/, ''); // remove the '0x' prefix if present
    const bytes = [];

    for (let i = 0; i < hexString.length; i += 2) {
        bytes.push(parseInt(hexString.substr(i, 2), 16));
    }

    return new TextDecoder().decode(new Uint8Array(bytes));
}

export const calculateRampPrices = async (
    asset: IToken,
    rate: BigNumber,
    paymentMethod: IChain,
    currency: IToken,
    type: IP2PTradeLiq['type'],
    allowKYP = true
): Promise<{ finalRate: IProviderRate; otherRates: IProviderRate[] } | false> => {
    console.log('calculateRampPrices');
    console.log(asset, rate, paymentMethod, currency, type);

    if (!currency || !currency?.symbol || !paymentMethod?.shortName) return false;
    if (!asset || !asset?.address || !asset?.decimals) return false;

    let chainId = asset?.chainId ?? get(ChainId);
    if(!chainId) throw new Error('ChainId not found');


    const w3 = await rpcLooper(chainId);
    if (!w3) return false;

    let rateList: IProviderRate[] = [];
    let rampType = type === 'ONRAMP' ? '1' : '0';
    let skipShift = new w3.eth.Contract(ABI.skipShift, SkipShiftContracts[chainId]) as unknown as SkipShiftCore;

    //get asset listings
    let listings: IProviderAssets = (await skipShift.methods.getAssetListings(0, asset.address).call()) as unknown as IProviderAssets;
    console.log('calculateRampPrices listings: ', listings);

    let ramps = listings[0];
    let venues = listings[1];

    let min: BigNumber = new BigNumber(0);
    let max: BigNumber = new BigNumber(0);

    // let recentCancels = await getRecentlyCancelledOrders(wallet.address, chainId);
    // recentCancels = recentCancels?.slice(0, 3);
    // //
    // console.log('### - 3.1 - recentCancels: ', recentCancels);

    for (let i = 0; i < ramps.length; i++) {
        const ramp = ramps[i];
        const venueList = venues[i];
        if (!ramp.active) continue;
        console.log('ramp.maker', ramp.maker);
        if (ramp?.maker && ramp.maker.toLowerCase() == '0x9b824F4b003A301C1496B2d924Fb773B3a15123C'.toLowerCase()) continue; //fuk dis guy

        if (ramp.currency != w3.utils.asciiToHex(currency.symbol)) continue;

        if (!allowKYP && hexToUtf8(ramp[12])?.length > 3) {
            //check length of kypFields string
            console.log('skipping kyp listing');
            continue;
        }
        // TODO: Need to redux this to actually work
        // NOTE: DO NOT DELETE
        // if (recentCancels && deepCheck(recentCancels, ramp.maker)) continue;
        // if (ramp.maker.toLocaleLowerCase() === wallet.address.toLocaleLowerCase()) continue;
        // NOTE: DO NOT DELETE

        // if (!Object.values(venues[i].flat()).includes(paymentMethod?.shortName)) continue;

        if (venueList.toString().search(new RegExp(paymentMethod?.shortName, 'i')) < 0) continue;
        if (ramp.buyOrSell != rampType) continue;

        // NOTE: activates only in case of IPFS hosting, the server handles it otherwise
        if (window?.location?.host && window.location.host.search('skipshift') < 0) {
            const cked = await checkMakerCollateralized(ramp, chainId); // 'cucked' lol
            console.log('### - 3.2 - cked: ', cked);
            if (!cked) continue;
        }

        //CHECK COLLATERAL AND SPREAD LIMIT
        const isCollateralized = await verifyActive(ramp.maker, ramp.tier as string, chainId);
        console.log('calculateRampPrices isCollateralized', isCollateralized);
        if (!isCollateralized) continue;
        const isAboveSpreadLimit = +ramp.spread >= SPREAD_LIMIT;
        if (isAboveSpreadLimit) continue;

        min = BigNumber(ramp.min).lt(min) ? BigNumber(ramp.min) : min;
        max = BigNumber(ramp.max).gt(max) ? BigNumber(ramp.max) : max;
        let spread = ramp.spread;
        let totalAmount = type == 'ONRAMP' ? rate.minus(rate.times(spread).div(DIVISOR)) : rate.minus(rate.times(spread).div(DIVISOR));

        let providerRate: IProviderRate = {
            spread: spread,
            totalAmount: totalAmount,
            maker: ramp.maker,
            venue: venues[i],
            listingId: i,
            min: BigNumber(ramp.min),
            max: BigNumber(ramp.max),
            reqCollateral: ramp.reqCollateral,
            kypString: hexToUtf8(ramp[12] || '') //kypFields
        };

        rateList.push(providerRate);
    }

    const noRates: IProviderRate = {
        spread: 0,
        totalAmount: new BigNumber(0),
        maker: '',
        venue: [],
        listingId: 0,
        min: BigNumber(0),
        max: BigNumber(0),
        kypString: ''
    };

    if (rateList.length === 0) {
        return { finalRate: noRates, otherRates: [] };
    }

    //BEGIN FILTERS

    //COMPARE TIME RANGES, throw out any ramps that are not in the range
    // let timeRangeTestListing = JSON.parse(JSON.stringify(rateList[0]));
    // timeRangeTestListing.maker = 'test';
    // timeRangeTestListing.min = BigNumber(timeRangeTestListing.min);
    // timeRangeTestListing.max = BigNumber(timeRangeTestListing.max);
    // timeRangeTestListing.totalAmount = BigNumber(timeRangeTestListing.totalAmount);
    // rateList.push(timeRangeTestListing);
    console.log('ratelist before timerange filter', rateList);
    let timeRangeFilteredRates = await timeRangeFilter(rateList, chainId);
    if (timeRangeFilteredRates.length == 0) {
        // addActionLogLine("No providers currently online. You may experience longer trade times.")
        console.log('no listings available for time range');
        timeRangeFilteredRates = rateList;
    } else {
        rateList = timeRangeFilteredRates;
    }
    // console.log('rateListFiltered timeRange', timeRangeFilteredRates);

    // let spreadMinMaxTestListing: IProviderRate = JSON.parse(JSON.stringify(timeRangeFilteredRates[0]));
    // spreadMinMaxTestListing.spread = "1000";
    // spreadMinMaxTestListing.min = BigNumber(spreadMinMaxTestListing.min);
    // spreadMinMaxTestListing.max = BigNumber(spreadMinMaxTestListing.max);
    // spreadMinMaxTestListing.totalAmount = BigNumber(spreadMinMaxTestListing.totalAmount);
    // timeRangeFilteredRates.push(spreadMinMaxTestListing);
    // console.log('rates before spread filter', timeRangeFilteredRates);
    //COMPARE SPREAD
    let spreadMinMaxFilteredRates = await spreadMinMaxFilter(timeRangeFilteredRates, chainId, asset, rate);
    if (spreadMinMaxFilteredRates.length == 0) {
        // addActionLogLine()
        console.log('outside of minmax range'); //this should never happen, there will always be at least 1 valid rate if it gets this far
        return { finalRate: noRates, otherRates: [] };
    } else {
        rateList = spreadMinMaxFilteredRates;
    }
    // console.log('rateListFiltered spreadMinMax', spreadMinMaxFilteredRates);

    //COMPARE TRADELOCKED (use pickOne)
    // let tradeLockedTestListing = JSON.parse(JSON.stringify(spreadMinMaxFilteredRates[0]));
    // tradeLockedTestListing.maker = 'test';
    //UNCOMMENT TO TEST TL FILTER
    // spreadMinMaxFilteredRates.push(tradeLockedTestListing);
    // console.log('tradelockedtestlisting', tradeLockedTestListing);
    // console.log('rates before tradelocked filter', spreadMinMaxFilteredRates);
    let tradeLockedFilteredRates = await tradeLockedFilter(spreadMinMaxFilteredRates, chainId, true);
    let finalRate: IProviderRate;
    if (tradeLockedFilteredRates.length == 0) {
        console.error('no valid rates found after filters (this shouldnt happen)');
        return { finalRate: noRates, otherRates: [] };
    } else {
        finalRate = tradeLockedFilteredRates[0];
    }
    console.log('rateListFiltered tradelocked', tradeLockedFilteredRates);
    console.log('ratelist finalrate', finalRate);

    //remove finalRate from list
    rateList = rateList.filter((rate) => rate !== finalRate);
    //return finalRate and the list of all other possible listings to match with
    return { finalRate: finalRate, otherRates: rateList };
};

export const createP2pTrade = async (data: IP2PTradeLiq, autoCollaterallize: boolean = true): Promise<boolean> => {
    let wallet = get(Wallet);
    let chainId = data.asset.chainId || get(ChainId);
    let referrer = get(Referrer) as string;
    if (referrer.toString().match(/(\b0x[a-f0-9]{40}\b)/g) === null) referrer = Contracts.ZERO;

    const w3 = get(web);
    if (!w3) {
        TxError.set(ActionLogTexts[get(Locale)].missing_node_connection);
        return false;
    }

    if (!data.amount || !data.asset.decimals) {
        TxError.set(ActionLogTexts[get(Locale)].missing_some_trade_data);
        return false;
    }

    let amount = new BigNumber(
        BigNumber(data.amount)
            .times(10 ** data.asset.decimals)
            .dp(0)
            .toFixed(0)
    );

    let skipShift = new w3.eth.Contract(ABI.skipShift, SkipShiftContracts[chainId]) as unknown as SkipShiftCore;

    let index0 = await skipShift.methods.rampIndex().call();
    let collat = await skipShift.methods.collateralToken().call();

    let nativeForCollat: BigNumber = new BigNumber(0);
    const neededCollat = await collateralNeeded(chainId);

    if (neededCollat?.gt(0) && autoCollaterallize) {
        // get quote for collateral
        const base = await getTokenInfo(w3, Contracts.BASE, chainId, true);
        const collateral = await getTokenInfo(w3, collat, chainId, true);
        const quote = await getPriceQuote(neededCollat, base[0], collateral[0], chainId, true);

        if (!quote || !base[0]?.decimals) {
            TxError.set(ActionLogTexts[get(Locale)].could_not_get_collateral_quote);
            return false;
        }

        nativeForCollat = quote.leftAmount
            .times(10 ** base[0].decimals)
            .times(1.01)
            .dp(0);
    }

    let aprvTx = false;
    if (data.type == 'OFFRAMP' || (neededCollat?.gt(0) && data.reqCollateral)) {
        aprvTx = await handleApproval(skipShift, data, wallet, chainId, w3, autoCollaterallize && nativeForCollat.gt(0));
        if (!aprvTx) {
            TxError.set(ActionLogTexts[get(Locale)].approval_failed_retry);
            return false;
        }
    }

    let collateralTokenAddress = await skipShift.methods.collateralToken().call();
    let collateralToken = new w3.eth.Contract(ABI.erc20, collateralTokenAddress) as unknown as ERC20;
    let decimals = await collateralToken.methods.decimals().call();
    let collateralBalance = await collateralToken.methods.balanceOf(wallet.address).call();
    let bal = BigNumber(collateralBalance).div(BigNumber(10).pow(decimals));

    if (neededCollat.gt(0) && bal.gte(neededCollat)) nativeForCollat = BigNumber(0);

    addActionLogLine(ActionLogTexts[get(Locale)].encrypting_payment_info, 'text-green-200');

    if (!data.venue || !data.maker) {
        TxError.set(ActionLogTexts[get(Locale)].missing_required_data_for_trade);
        return false;
    }

    const pload = await buildPayload(index0, data.venue, undefined, data.kypInfo);
    if (!pload) {
        TxError.set(ActionLogTexts[get(Locale)].could_not_build_payload);
        return false;
    }

    console.log('pload', pload);

    let loadDropped = await submitEncryptedPayload(index0, data.maker, pload);
    if (!loadDropped) loadDropped = await submitEncryptedPayload(index0, data.maker, pload);

    if (!loadDropped) {
        TxError.set(ActionLogTexts[get(Locale)].could_not_submit_encrypted_payload);
        return false;
    }
    addActionLogLine(ActionLogTexts[get(Locale)].payment_info_sent);

    let sendNative = data.asset.address?.toLowerCase() === Contracts.BASE.toLowerCase() && data.type == 'OFFRAMP';
    addActionLogLine(ActionLogTexts[get(Locale)].creating_p2p_trade);

    let tx = await submitTrade(skipShift, data, amount.dp(0), referrer, wallet, sendNative, nativeForCollat);
    console.log('tx: ', tx);

    if (!tx) {
        // TxError.set(ActionLogTexts[get(Locale)].trade_failed_retry);
        return false;
    }
    // if (!!tx?.error) {
    //     TxError.set(tx?.error || ActionLogTexts[get(Locale)].trade_failed_retry);
    //     return false;
    // }
    // if (!tx) {
    //     TxError.set(ActionLogTexts[get(Locale)].trade_failed_retry);
    //     return false;
    // }

    return true;
};

export const getRecentlyCancelledOrders = async (address: string, chainId: number) => {
    console.log('getRecentlyCancelledOrders...');
    console.log('getRecentlyCancelledOrders address: ', address);
    console.log('getRecentlyCancelledOrders chainId: ', chainId);
    if (!address || !chainId) return [];
    const w3 = await rpcLooper(chainId);
    if (!w3) return;

    console.log('getRecentlyCancelledOrders: got w3');
    let skipShift;
    let trades;

    try {
        skipShift = new w3.eth.Contract(ABI.skipShift, SkipShiftContracts[chainId]) as unknown as SkipShiftCore;
        trades = await skipShift.methods['getUserTrades(uint256,address)'](0, address).call();
    } catch (e) {
        console.log('getRecentlyCancelledOrders error: ', e);
        return [];
    }

    console.log('getRecentlyCancelledOrders trades: ', trades);
    if (!trades || trades.length === 0) return [];

    let sorted = [...trades].sort((a, b) => Number(b.tradeId) - Number(a.tradeId));
    let returnData: IP2PLiquidityLite[] = [];

    for (let i = 0; i < sorted.length; i++) {
        const trade = sorted[i];

        if (trade.status !== '1') continue;
        if (trade.maker == address) continue;

        returnData.push({
            id: Number(trade.tradeId),
            maker: trade.maker
        });
    }

    return returnData;
};

export const cancelTrade = async (trade: IP2PTrade) => {
    let options: ITransactionOptions = get(TransactionOptions);
    let chainId = trade.asset.chainId || get(ChainId);

    const w3 = await rpcLooper(chainId);
    if (!w3) return false;
    let wallet = get(Wallet);

    if (!trade.tradeId) return false;

    let skipShift = new w3.eth.Contract(ABI.skipShift, SkipShiftContracts[chainId]) as unknown as SkipShiftCore;

    let txHash: string = '';
    let checkTx = false;

    const tx = skipShift.methods.cancelRampTrade(trade.tradeId);

    const gas = BigNumber(
        await tx.estimateGas({
            from: wallet.address
        })
    )
        .times(chainId == 109 ? 2 : 1.25)
        .toFixed(0);

    await tx
        .send({
            from: wallet.address,
            gas: gas
        })
        .on('transactionHash', (hash: string) => {
            addActionLogLine(ActionLogTexts[get(Locale)].processing_transaction);
            txHash = hash;
        })
        .on('receipt', function (receipt) {
            addActionLogLine(ActionLogTexts[get(Locale)].transaction_sent);
            trackEvent('trade', 'cancel', trade.type, trade.asset.symbol || 'unknown');
        })
        .on('error', function (error: any) {
            if (error.code === 4001) addActionLogLine(ActionLogTexts[get(Locale)].transaction_rejected);
            else if (error.code === -32000) checkTx = true;
            else addActionLogLine(ActionLogTexts[get(Locale)].error_encountered);
        });

    if (checkTx) {
        addActionLogLine(ActionLogTexts[get(Locale)].rpc_janky_checking);
        let txStatus = await w3.eth.getTransactionReceipt(txHash);
        if (txStatus.status) {
            addActionLogLine(ActionLogTexts[get(Locale)].processing_transaction);
            return true;
        } else {
            addActionLogLine(ActionLogTexts[get(Locale)].transaction_failed);
            return false;
        }
    }

    return true;
};

export const requestArbitration = async (data: IP2PTrade) => {
    let chainId = data.asset.chainId || get(ChainId);

    const w3 = await rpcLooper(chainId);
    if (!w3) return false;
    let wallet = get(Wallet);

    let skipShift = new w3.eth.Contract(ABI.skipShift, SkipShiftContracts[chainId]) as unknown as SkipShiftCore;

    // TODO: add gas price estimation like in submitTrade
    await new Promise((resolve, reject) => {
        skipShift.methods
            .requestArbitration(data.tradeId!)
            .send({
                from: wallet.address
            })
            .on('transactionHash', (_hash: string) => {
                addActionLogLine(ActionLogTexts[get(Locale)].processing_transaction);
                // txHash = hash;
            })
            .on('receipt', function (receipt) {
                addActionLogLine(ActionLogTexts[get(Locale)].arbitration_requested);
                //addActionLogLine('P2P liquidity created!', 'text-green-400');
                trackEvent('trade', 'arbitration', data.type, data.asset.symbol || 'unknown');

                resolve(receipt);
            });
    });
};

export const submitConfirmation = async (order: IP2PTrade) => {
    if (!order || !order.tradeId || !order.venue || !order.asset.address || !order.maker || !order.taker) return false;
    const chainId = order.asset.chainId || get(ChainId);
    const web3 = get(web);
    console.log('order: ', order);
    console.log('web3: ', await web3.eth.getChainId());
    // if (!web3 || !order.venue || !order.asset.address || !order.maker || !order.taker || !order.tradeId || !order.confId) return false;
    const wallet = get(Wallet);
    console.log('wallet: ', wallet);

    let venueId = order.taker === wallet.address && order.type === 'ONRAMP' ? order.venue.takerId : order.venue.makerId;
    let paymentConfId = order.confId;

    let skipShift = new web3.eth.Contract(ABI.skipShift, SkipShiftContracts[chainId]) as unknown as SkipShiftCore;

    addActionLogLine(ActionLogTexts[get(Locale)].submitting_payment_confirmation);

    let confHash = web3.utils.soliditySha3(paymentConfId ?? '');
    if (!confHash) return false;

    let rcpt;
    let txHash: string = '';
    let checkTx = false;
    const tx = skipShift.methods.submitConfirmation(order.tradeId.toString(), confHash);

    addActionLogLine(ActionLogTexts[get(Locale)].sending_encrypted_confirmation);
    const counterparty = wallet.address.toLowerCase() === order.maker.toLowerCase() ? order.taker : order.maker;
    const pload = await buildPayload(order.tradeId, order.venue, paymentConfId);
    if (!pload) return false;
    const loadDropped = await submitEncryptedPayload(order.tradeId, counterparty, pload);
    addActionLogLine(ActionLogTexts[get(Locale)].encrypted_confirmation_sent);

    try {
        const gas = BigNumber(
            await tx.estimateGas({
                from: wallet.address
            })
        )
            .times(chainId == 109 ? 2 : 1.25)
            .toFixed(0);

        await tx
            .send({
                from: wallet.address,
                gas: gas
            })
            .on('transactionHash', (hash: string) => {
                addActionLogLine(ActionLogTexts[get(Locale)].processing_transaction);
                txHash = hash;
            })
            .on('receipt', function (receipt: any) {
                addActionLogLine(ActionLogTexts[get(Locale)].payment_confirmed_on_chain);
                rcpt = receipt;
                trackEvent('trade', 'submit_conf', order.type, order.asset.symbol || 'unknown');
            })
            .on('error', function (error: any) {
                if (error.code === 4001) addActionLogLine(ActionLogTexts[get(Locale)].transaction_rejected);
                else if (error.code === -32000) checkTx = true;
                else addActionLogLine(ActionLogTexts[get(Locale)].error_encountered);

                if (!checkTx) return false;
            });

        if (checkTx) {
            addActionLogLine(ActionLogTexts[get(Locale)].rpc_janky_checking);
            let txStatus = await web3.eth.getTransactionReceipt(txHash);
            if (txStatus?.status) {
                addActionLogLine(ActionLogTexts[get(Locale)].payment_confirmed_on_chain);
                rcpt = txStatus;
            } else {
                addActionLogLine(ActionLogTexts[get(Locale)].payment_confirmation_failed);
                return false;
            }
        }
    } catch (e) {
        console.log('submitTrade error: ', e);
        addActionLogLine(ActionLogTexts[get(Locale)].confirmation_failed_check_wallet);
        return false;
    }

    return true;
};

export const completeTrade = async (order: IP2PTrade): Promise<boolean> => {
    const chainId = order.asset.chainId || get(ChainId);
    const web3 = await rpcLooper(chainId);
    if (!web3) return false;
    const wallet = get(Wallet);

    let skipShift = new web3.eth.Contract(ABI.skipShift, SkipShiftContracts[chainId]);
    let rcpt = null;
    let hash = '';
    let checkTx = false;
    addActionLogLine(ActionLogTexts[get(Locale)].completing_trade);

    const tx = skipShift.methods.completeTrade(order.tradeId);

    console.log('completing trade', order);

    try {
        const gas = BigNumber(
            await tx.estimateGas({
                from: wallet.address
            })
        )
            .times(chainId == 109 ? 2 : 1.25)
            .toFixed(0);

        await tx
            .send({
                from: wallet.address,
                gas: gas
            })
            .on('transactionHash', (hsh: string) => {
                addActionLogLine(ActionLogTexts[get(Locale)].processing_transaction);
                hash = hsh;
            })
            .on('receipt', function (receipt: any) {
                addActionLogLine(ActionLogTexts[get(Locale)].trade_complete);
                rcpt = receipt;

                trackEvent('trade', 'complete', order.type, order.asset.symbol || 'unknown');
            })
            .on('error', function (error: any) {
                if (error.code === 4001) addActionLogLine(ActionLogTexts[get(Locale)].transaction_rejected);
                else if (error.code === -32000) checkTx = true;
                else addActionLogLine(ActionLogTexts[get(Locale)].error_encountered);

                if (!checkTx) return false;
            });

        if (checkTx) {
            addActionLogLine(ActionLogTexts[get(Locale)].rpc_janky_checking);
            let txStatus = await web3.eth.getTransactionReceipt(hash);
            if (txStatus?.status) {
                addActionLogLine(ActionLogTexts[get(Locale)].trade_complete);
                rcpt = txStatus;
                return true;
            } else {
                addActionLogLine(ActionLogTexts[get(Locale)].transaction_failed);
                return false;
            }
        }
    } catch (e) {
        console.log('submitTrade error: ', e);
        addActionLogLine(ActionLogTexts[get(Locale)].trade_completion_failed_check_wallet);
        return false;
    }
    addActionLogLine(ActionLogTexts[get(Locale)].p2p_trade_complete);

    return true;
};

export const fetchLatestTradeTimestamp = async (): Promise<number> => {
    const resp = await axios.get('https://skipscan.win/api/get-latest-trade-timestamp');
    if (resp) {
        return +resp.data.latestTimestamp;
    } else {
        return 0;
    }
};

export const getP2pTrades = async (wallet: string, startPage: number = 1): Promise<IP2PTradeLiq[]> => {
    const chainId = get(ChainId);
    const web3 = await rpcLooper(chainId);
    const currencyRates = get(CurrencyRates);
    if (!web3) return [];

    // wallet = "0x6824F52144a79c0E17f5e1Ca2286bc1616d8DD4a"

    const chains = get(Chains);
    const stable: IToken = chains[chainId]?.stableCoins?.[0];
    if (!stable || !SkipShiftContracts[chainId] || !wallet) return [];

    const skipShift = new web3.eth.Contract(ABI.skipShift, SkipShiftContracts[chainId]) as unknown as SkipShiftCore;
    let userTradeIndex = (await skipShift.methods.userInfo(wallet).call()).tradeIndex;

    let tradeStart = Math.max(0, parseInt(userTradeIndex) - startPage * PAGE_SIZE); //never allow a negative start index
    let tradeEnd = tradeStart + PAGE_SIZE;

    let userTrades = (await skipShift.methods
        ['getUserTrades(uint256,uint256,address)'](0, userTradeIndex, wallet)
        .call()
        .catch(() => []));

    console.log('userTrades: ', userTrades);
    console.log('userTradeIndex: ', userTradeIndex);
    let activeTrades = [];
    let completedTrades = [];
    for (let i = userTrades.length - 1; i > -1; i--) {
        const el = userTrades[i];
        if (el?.maker && el?.maker == Contracts.ZERO) continue;

        if (hasCompleted(+el.status)) completedTrades.push(el);
        else activeTrades.push(el);
    }
    console.log('splitter activeTrades: ', activeTrades);
    console.log('splitter  completedTrades: ', completedTrades);

    // let userTradesSliced = userTrades.slice(0, PAGE_SIZE);
    let userTradesSliced = [activeTrades.slice(0, PAGE_SIZE), completedTrades.slice(0, PAGE_SIZE)].flat();
    console.log('userTradesSliced: ', userTradesSliced);

    // make sure to remove any trade that doesn't have the connected wallet as either the taker or maker
    userTradesSliced = userTradesSliced.filter(
        (trade) => trade.maker?.toLowerCase() === wallet.toLowerCase() || trade.taker?.toLowerCase() === wallet.toLowerCase()
    );

    XMTPFragmentData.set({ loaded: 0, total: userTradesSliced.length * 2 })
    const processTrade = async (userTrade: Ancillaries.RampTradeStructOutput): Promise<IP2PTradeLiq | null> => {
        if (userTrade.asset === Contracts.ZERO) return null;

        const [token, rampLiquidity] = await Promise.all([
            getTokenInfo(web3, userTrade.asset, chainId),
            skipShift.methods.getAccountListings(0, userTrade.asset, userTrade.maker).call()
        ]);

        const direction: IP2PTradeLiq['type'] = userTrade.tradeDirection === '0' ? 'OFFRAMP' : 'ONRAMP';
        const tradeAmount = new BigNumber(userTrade.amount || '').div(new BigNumber(10).pow(token[0]?.decimals || 18));
        const matchedLiquidity = rampLiquidity[0].find((liq: any) => liq.listingId === (userTrade.listingId ?? '').toString());
        const currency = currencies.find((c) => '0x' + ascii_to_hexa(c.symbol || '') === matchedLiquidity?.currency);
        let totalCost = new BigNumber(userTrade.totalCost || 0);

        if (!userTrade?.status) return null;
        if (+userTrade?.status == 0) {
            let quote: IQuoteResult | undefined = await getPriceQuote(tradeAmount, token[0], stable, chainId);
            if (!quote) {
                quote = {
                    leftAmount: new BigNumber(0),
                    rightAmount: new BigNumber(0),
                    base: '',
                    path: [''],
                    approvalNeeded: false
                };
            }

            const rate = quote.rightAmount;
            const listingInfo = await skipShift.methods.getAssetListings(0, userTrade.asset).call();
            const spread = new BigNumber(listingInfo[0][+userTrade.listingId].spread);
            totalCost = direction === 'ONRAMP' ? rate.plus(rate.times(spread).div(DIVISOR)) : rate.minus(rate.times(spread).div(DIVISOR));
            if (currency?.symbol !== 'USD') {
                totalCost = totalCost.times(currencyRates[currency?.symbol || 'USD']);
            }
        }

        try {
            let fragmentData = get(XMTPFragmentData)
            XMTPLoadingProgress.set((fragmentData.loaded / fragmentData.total) * 100);
            XMTPFragmentData.set({ loaded: fragmentData.loaded + 1, total: fragmentData.total })
        } catch (e) {
            console.log('could not set XMTPLoadingProgress');
        }

        LastScannedTimestamp.set(Math.floor(Date.now() / 1000));

        return {
            amount: tradeAmount.toFixed(),
            asset: token[0],
            currency,
            destination: userTrade.destination,
            tradeId: userTrade.tradeId,
            listingId: Number(userTrade.listingId),
            kypFields: hexToUtf8(matchedLiquidity?.[12]),
            rampPhase: userTrade.status,
            taker: userTrade.taker,
            maker: userTrade.maker,
            type: direction,
            totalCost: totalCost.toFixed(),
            venue: { platform: userTrade.venue?.platform, identHash: userTrade.venue?.identHash, userId: '', makerId: '', takerId: '' },
            active: false,
            reqCollateral: false,
            assetType: '',
            tier: '',
            min: '',
            max: '',
            venues: []
        } as unknown as IP2PTradeLiq;
    };

    const tradesPromises = [];
    for (let i = 0; i < userTradesSliced.length; i++) {
        tradesPromises.push(processTrade(userTradesSliced[i]));
    }

    const trades = (await Promise.all(tradesPromises)).filter((trade) => trade !== null) as IP2PTradeLiq[];
    const tradeIds = trades.map((trade) => parseInt(String(trade?.tradeId)));
    const loadz = await getLoadz(tradeIds);

    for (let i = 0; i < trades.length; i++) {
        const trade = trades[i];
        const id = trade.tradeId;
        if (trade.venue && id) {
            //@ts-expect-error
            trade.venue.makerId = loadz[id]?.maker_venue_id;
            //@ts-expect-error
            trade.venue.takerId = loadz[id]?.taker_venue_id;
            //@ts-expect-error
            trade.confId = loadz[id]?.payment_conf_id;
            //@ts-expect-error
            trade.kypInfo = loadz[id]?.kyc_info;
        }
    }

    // trades.reverse();
    // P2PCache.set(trades);
    return trades;
};

// @note: this is for retrieving the user's liquidity, not trade liquidity
export const getUserLiqList = async (wallet: string, chains?: number[]) => {
    const availableChains = chains ?? [43114, 1, 56, 109, 369];

    let list: IP2PTradeLiq[] = [];

    try {
        const resp = await fetchAddressListings(wallet);
        console.log('user liq list', resp);

        return resp;
    } catch (e) {
        console.log('skipscan server user liq list failed');
    }

    for (let i = 0; i < availableChains.length; i++) {
        if (!SkipShiftContracts[availableChains[i]] || !wallet) return list;
        const web3 = await rpcLooper(availableChains[i]);
        if (!web3) return;

        let skipShift = new web3.eth.Contract(ABI.skipShift, SkipShiftContracts[availableChains[i]]) as unknown as SkipShiftCore;
        let assets: string[];

        try {
            assets = await skipShift.methods.getProviderAssets(wallet).call();
        } catch (e) {
            console.log('liq list', e);
            return [];
        }
        // console.log('assets', assets);
        //j==unique user assets
        try {
            for (let j = 0; j < assets.length; j++) {
                const resp: IProviderAssets = (await skipShift.methods.getAccountListings('0', assets[j], wallet).call()) as unknown as IProviderAssets;

                //k==asset listings count
                for (let k = 0; k < resp[0].length; k++) {
                    let hexa = resp[0][k].currency;
                    let currency = currencies.find((c) => '0x' + ascii_to_hexa(c.symbol || '') === hexa);

                    if (resp[1][k].length == 0) continue;

                    let token = (await getTokenInfo(web3, assets[j], availableChains[i]))[0];
                    // console.log('token in getUserLiqList', token);
                    let liq: IP2PTradeLiq = {
                        // @ts-ignore
                        id: resp[2][k],
                        active: resp[0][k].active,
                        assetType: resp[0][k].assetType,
                        type: resp[0][k].buyOrSell == 0 ? 'OFFRAMP' : 'ONRAMP',
                        currency: currency,
                        maker: resp[0][k].maker,
                        max: resp[0][k].max,
                        min: resp[0][k].min,
                        reqCollateral: resp[0][k].reqCollateral,
                        spread: resp[0][k].spread,
                        taker: resp[0][k].taker,
                        tier: resp[0][k].tier,
                        venues: resp[1][k],
                        asset: token
                    };
                    list.push(liq);
                }
            }
        } catch (e) {
            console.log(e);
        }
    }

    const activeListings = list.filter((listing) => listing.active == true);
    console.log('user liq list', activeListings);
    return activeListings;
};

async function submitTrade(
    skipShift: SkipShiftCore,
    data: IP2PTradeLiq,
    amount: BigNumber,
    referrer: string,
    wallet: IWallet,
    sendNative: boolean,
    amountForCollat: BigNumber
) {
    console.log('submitTrade sendNative: ', sendNative);
    let rcpt = null;
    let hash = '';
    let checkTx = false;
    let chainId = data.asset.chainId || get(ChainId);
    // const rpcLooper = await rpcLooper(chainId);

    // switched to just get the current connection because
    /// that connection should be where the wallet is connected
    // const w3 = await rpcLooper(chainId);
    const w3 = get(web);
    if (!w3) {
        TxError.set(ActionLogTexts[get(Locale)].not_connected_to_wallet);
        return null;
    }

    if (!data.venue || !data.asset.address || !data.destination || !data.listingId) {
        TxError.set(ActionLogTexts[get(Locale)].missing_required_data_for_trade);
        return false;
    }

    const tx = skipShift.methods.initiateRampTrade(data.listingId, amount.dp(0).toString(), data.asset.address, data.destination, referrer, [
        data.venue.platform,
        data.venue.identHash
    ]);

    const sendValue = sendNative ? amount.plus(amountForCollat).dp(0).toString() : amountForCollat.dp(0).toString();

    let gas = '0';

    try {
        gas = BigNumber(
            await tx.estimateGas({
                from: wallet.address,
                value: sendValue
            })
        )
            .times(chainId == 109 ? 2 : 1.25)
            .dp(0)
            .toString();
    } catch (e) {
        console.log('gas estimation error: ', e);
        // TxError.set(ActionLogTexts[get(Locale)].could_not_estimate_gas);
        const locale = get(Locale);
        const native = chains[chainId].nativeCurrency.symbol;
        TxError.set(`${ActionLogTexts[locale].insufficient_funds_for_trade_1} ${native}!`);
        addActionLogLine(`${ActionLogTexts[locale].insufficient_funds_for_trade_1} ${native}!`);
        return null;
    }

    try {
        await tx
            .send({
                from: wallet.address,
                value: sendValue,
                gas: gas
            })
            .on('transactionHash', (hsh: string) => {
                addActionLogLine(ActionLogTexts[get(Locale)].processing_transaction);
                hash = hsh;
            })
            .on('receipt', function (receipt: any) {
                addActionLogLine(ActionLogTexts[get(Locale)].p2p_trade_initiated);
                rcpt = receipt;
                trackEvent('trade', 'initiate', data.type, data.asset.symbol || 'unknown');
            })
            .on('error', function (error: any) {
                if (error.code === 4001) addActionLogLine(ActionLogTexts[get(Locale)].transaction_rejected);
                else if (error.code === -32000) checkTx = true;
                else {
                    addActionLogLine(ActionLogTexts[get(Locale)].error_encountered);
                    TxError.set(ActionLogTexts[get(Locale)].error_encountered);
                }

                console.log('checkTx: ', checkTx);
                if (!checkTx) return false;
            });

        if (checkTx || !rcpt) {
            addActionLogLine(ActionLogTexts[get(Locale)].rpc_janky_checking);
            let txStatus = await w3.eth.getTransactionReceipt(hash);
            if (txStatus?.status) {
                if (txStatus.status) {
                    addActionLogLine(ActionLogTexts[get(Locale)].p2p_trade_initiated);
                    rcpt = txStatus;

                    trackEvent('trade', 'initiate', data.type, data.asset.symbol || 'unknown');
                    return rcpt;
                } else {
                    addActionLogLine(ActionLogTexts[get(Locale)].trade_failed_retry);
                    TxError.set(ActionLogTexts[get(Locale)].trade_failed_retry);
                    return false;
                }
            } else return false;
        } else return true;
    } catch (e: any) {
        console.log('submitTrade error: ', e);
        addActionLogLine(ActionLogTexts[get(Locale)].trade_submission_failed_check_wallet);
        TxError.set(ActionLogTexts[get(Locale)].trade_submission_failed_check_wallet);

        // TxError.set(e.message);

        return { error: e.message };
    }
}

async function handleApproval(skipShift: SkipShiftCore, data: IP2PTradeLiq, wallet: IWallet, chainId: number, w3: Web3, autoCollateralize: boolean = false) {
    console.log('handling approval for: ', data);
    if (!data.asset.address) return false;
    let collateralToken = await skipShift.methods.collateralToken().call();
    let userCollateral = await skipShift.methods.requiredCollateral().call();
    let collatAmt = get(Collateral).minimumCollateral;

    let approvalNeeded =
        data.type === 'OFFRAMP'
            ? await checkApproval(wallet.address, data.asset.address, SkipShiftContracts[chainId], chainId, BigNumber(data.amount || 0))
            : false;
    let approvalNeededCollateral =
        data.type === 'ONRAMP' && !autoCollateralize
            ? await checkApproval(wallet.address, collateralToken, SkipShiftContracts[chainId], chainId, BigNumber(collatAmt))
            : false;

    try {
        if (approvalNeeded) {
            let erc20 = new w3.eth.Contract(ABI.erc20, data.asset.address);
            addActionLogLine(ActionLogTexts[get(Locale)].approving_x + data.asset.symbol);

            let gas = await erc20.methods.approve(SkipShiftContracts[chainId], BigNumber(data.amount || 0).times(10 ** (data.asset.decimals || 18)).toFixed(0)).estimateGas({
                from: wallet.address
            });

            await erc20.methods.approve(SkipShiftContracts[chainId], BigNumber(data.amount || 0).times(10**(data.asset.decimals || 18)).toFixed(0)).send({ from: wallet.address, gas: gas });
            addActionLogLine(data.asset.symbol + ActionLogTexts[get(Locale)].x_approved);
        }
        if (approvalNeededCollateral) {
            let erc20 = new w3.eth.Contract(ABI.erc20, collateralToken);
            addActionLogLine(ActionLogTexts[get(Locale)].approving_collateral_token);

            let gas = await erc20.methods.approve(SkipShiftContracts[chainId], userCollateral).estimateGas({
                from: wallet.address
            });

            await erc20.methods.approve(SkipShiftContracts[chainId], userCollateral).send({ from: wallet.address, gas: gas });
            addActionLogLine(ActionLogTexts[get(Locale)].collateral_token_approved);
        }
    } catch (e) {
        addActionLogLine(ActionLogTexts[get(Locale)].approval_failed_check_wallet);
        TxError.set(ActionLogTexts[get(Locale)].approval_failed_check_wallet);
        return false;
    }
    return true;
}

export async function checkMakerCollateralized(listing: IRampDetails, chainId: number) {
    console.log('### - 3.1 - checkMakerCollateralized: ', listing, '\nchainid: ', chainId);
    const w3 = await rpcLooper(chainId);
    if (!w3) return false;

    let skipShift = new w3.eth.Contract(ABI.skipShift, SkipShiftContracts[chainId]) as unknown as SkipShiftCore;

    let userInfo = await skipShift.methods.userInfo(listing.maker).call();
    console.log('userInfo: ', userInfo);

    let tierThresholds = await skipShift.methods.tierThresholds(listing.tier).call();
    console.log('tierThresholds: ', tierThresholds);

    let collateralized = BigNumber(userInfo.collateral).gte(tierThresholds);

    return collateralized;
}

export async function collateralNeeded(chainId: number, exponentiated: boolean = false): Promise<BigNumber> {
    const w3 = await rpcLooper(chainId);
    if (!w3) return new BigNumber(-1);
    let wallet = get(Wallet);

    try {
        let skipShift = new w3.eth.Contract(ABI.skipShift, SkipShiftContracts[chainId]) as unknown as SkipShiftCore;
        const token = await skipShift.methods.collateralToken().call();
        const minimumCollateral = await skipShift.methods.requiredCollateral().call();

        console.log('minimumCollateral: ', minimumCollateral);

        const colToken = new w3.eth.Contract(ABI.erc20, token) as unknown as ERC20;
        const collateralDecimals = await colToken.methods.decimals().call();

        let userInfo = await skipShift.methods.userInfo(wallet.address).call();
        let min = new BigNumber(minimumCollateral).times(parseInt(userInfo.tradeLocked) + 1);

        let collateralized = min.lte(userInfo.collateral) ? true : false;

        if (!collateralized) return min.minus(userInfo.collateral).div(exponentiated ? 1 : new BigNumber(10).exponentiatedBy(collateralDecimals));
        else return new BigNumber(0);
    } catch (e) {
        return new BigNumber(-1);
    }
}

export const preFetchCollateral = async (chainId: number): Promise<ICollatPreFetch | undefined> => {
    const w3 = await rpcLooper(chainId);
    if (!w3) return;
    try {
        let wallet = get(Wallet).address || Contracts.ZERO;

        console.log('preFetchCollateral wallet: ', wallet);

        let skipShift = new w3.eth.Contract(ABI.skipShift, SkipShiftContracts[chainId]) as unknown as SkipShiftCore;
        let collateralToken = await skipShift.methods.collateralToken().call();
        const minimumCollateral = await skipShift.methods.requiredCollateral().call();
        let erc20 = new w3.eth.Contract(ABI.erc20, collateralToken);
        const decimals = await erc20.methods.decimals().call();
        let allowance = await erc20.methods.allowance(wallet, SkipShiftContracts[chainId]).call();
        let balance = await erc20.methods.balanceOf(wallet).call();

        const requiredCollateral = await (async () => {
            if (wallet === Contracts.ZERO) return BigNumber(minimumCollateral).div(BigNumber(10).exponentiatedBy(decimals));
            let userInfo = await skipShift.methods.userInfo(wallet).call();
            let min = new BigNumber(minimumCollateral).times(parseInt(userInfo?.tradeLocked || '0') + 1);
            let collateralized = min.lte(userInfo.collateral) ? true : false;

            if (!collateralized) return min.minus(userInfo.collateral).div(BigNumber(10).exponentiatedBy(decimals));
            else return new BigNumber(0);
        })();

        let quote =
            (await getQuoteOut(
                BigNumber(requiredCollateral),
                { address: Contracts.BASE, decimals: 18, chainId },
                { address: collateralToken, decimals, chainId },
                true
            )) || undefined;

        Collateral.set({
            allowance: allowance,
            balance: balance,
            minimumCollateral: minimumCollateral,
            nativeForCollateral: quote.leftAmount.times(1.01),
            token: { address: collateralToken, decimals, chainId },
            quote: quote
        });

        return {
            allowance: allowance,
            balance: balance,
            minimumCollateral: minimumCollateral,
            nativeForCollateral: requiredCollateral,
            token: { address: collateralToken, decimals, chainId },
            quote: quote
        };
    } catch (e) {
        return undefined;
    }
};

export const decollateralize = async () => {
    const chainId = get(ChainId);

    const w3 = get(web);
    if (!w3) return;
    const wallet = get(Wallet);

    let skipShift = new w3.eth.Contract(ABI.skipShift, SkipShiftContracts[chainId]) as unknown as SkipShiftCore;
    let accessTokenAddy = await skipShift.methods.collateralToken().call();

    const userInfo = await skipShift.methods.userInfo(wallet.address).call();
    let collateralBalance = userInfo.collateral;

    if (!BigNumber(collateralBalance).gt(0)) return;

    let txHash = '';
    let checkTx = false;

    const tx = skipShift.methods.decollateralize();
    const gas = BigNumber(
        await tx.estimateGas({
            from: wallet.address
        })
    )
        .times(1.25)
        .toFixed(0);

    await tx
        .send({
            from: wallet.address,
            gas: gas
        })
        .on('transactionHash', (hash: string) => {
            addActionLogLine(ActionLogTexts[get(Locale)].processing_transaction);
            txHash = hash;
        })
        .on('receipt', function (receipt: any) {
            addActionLogLine(ActionLogTexts[get(Locale)].collateral_withdrawn);
            trackEvent('p2p', 'decollat', 'success', 'success');
        })
        .on('error', function (error: any) {
            if (error.code === 4001) addActionLogLine(ActionLogTexts[get(Locale)].transaction_rejected);
            else if (error.code === -32000) checkTx = true;
            else addActionLogLine(ActionLogTexts[get(Locale)].error_encountered);
        });

    if (checkTx) {
        addActionLogLine(ActionLogTexts[get(Locale)].rpc_janky_checking);
        let txStatus = await w3.eth.getTransactionReceipt(txHash);
        if (txStatus.status) {
            addActionLogLine(ActionLogTexts[get(Locale)].collateral_withdrawn);
            return true;
        } else {
            addActionLogLine(ActionLogTexts[get(Locale)].transaction_failed);
            return false;
        }
    }
};

export const getAddressInfo = async (address?: string, chainId?: number): Promise<IUserInfo | undefined> => {
    console.log('getAddressInfo address: ', address);
    chainId = chainId || get(ChainId);

    const w3 = await rpcLooper(chainId);
    if (!w3) return;
    const wallet = address || get(Wallet).address;
    if (!wallet) return;

    try {
        let skipShift = new w3.eth.Contract(ABI.skipShift, SkipShiftContracts[chainId]) as unknown as SkipShiftCore;
        let accessTokenAddy = await skipShift.methods.collateralToken().call();
        let accessToken = new w3.eth.Contract(ABI.erc20, accessTokenAddy) as unknown as ERC20;

        const userInfo: IUserInfo = await skipShift.methods.userInfo(wallet).call();
        userInfo.collatBal = await accessToken.methods.balanceOf(wallet).call();

        return userInfo;
    } catch (e) {
        return [] as unknown as IUserInfo;
    }
};

export const getCollateralTokenInfo = async (chainId?: number): Promise<IToken | undefined> => {
    chainId = chainId || get(ChainId);
    const w3 = await rpcLooper(chainId);
    if (!w3) return;

    const token: IToken = {};

    try {
        let skipShift = new w3.eth.Contract(ABI.skipShift, SkipShiftContracts[chainId]) as unknown as SkipShiftCore;
        let collateralTokenAddy = await skipShift.methods.collateralToken().call();
        token.address = collateralTokenAddy;
        let collateralToken = new w3.eth.Contract(ABI.erc20, collateralTokenAddy) as unknown as ERC20;
        token.name = await collateralToken.methods.name().call();
        token.symbol = await collateralToken.methods.symbol().call();
        token.decimals = +(await collateralToken.methods.decimals().call());

        return token;
    } catch (e) {
        console.error('error getting collat token info');
    }
};

export const getAllAssetsWeb3 = async (chainId: number): Promise<IToken[]> => {
    console.log('getting assets w/web3');

    const w3 = await rpcLooper(chainId);
    if (!w3) return [];
    if (!SkipShiftContracts[chainId]) return [];

    const inStore = get(P2PAssets);
    console.log('inStore: ', inStore);
    if (inStore && Math.abs(parseInt(inStore?.timestamp) - Date.now()) < 15 * 60 * 1000 && inStore.chain == chainId && inStore?.data?.length > 0)
        return inStore.data;

    let skipShift = new w3.eth.Contract(ABI.skipShift, SkipShiftContracts[chainId]) as unknown as SkipShiftCore;
    let assets: string[] = [];
    let events;
    const logsDecoder = LogsDecoder.create();

    logsDecoder.addABI(ABI.erc20);
    logsDecoder.addABI(ABI.skipShift);
    try {
        if (chainId == 56) {
            events = (
                await axios.get(
                    `https://api.bscscan.com/api?module=logs&action=getLogs&fromBlock=${SkipShiftStartBlocks[chainId]}&toBlock=latest&address=${SkipShiftContracts[chainId]}&topic0=0xf1bd45f179b5c1692c0bc5e67619b9785e28ac7e0dd0bbf0f6aa72674d12f719&apiKey=PPJX226ZJHTKINMR75JY2YPZJMMGI5Y5JQ`
                )
            ).data.result;
            events = logsDecoder.decodeLogs(events);
            assets = events.map((e: any) => w3.utils.toChecksumAddress(e.events[0].value));
        } else if (chainId == 369) {
            events = (
                await axios.get(
                    `https://scan.pulsechain.com/api?module=logs&action=getLogs&fromBlock=${SkipShiftStartBlocks[chainId]}&toBlock=latest&address=${SkipShiftContracts[chainId]}&topic0=0xf1bd45f179b5c1692c0bc5e67619b9785e28ac7e0dd0bbf0f6aa72674d12f719`
                )
            ).data.result;
            events = logsDecoder.decodeLogs(events);
            assets = events.map((e: any) => w3.utils.toChecksumAddress(e.events[0].value));
        } else if (chainId == 5) {
            events = (
                await axios.get(
                    `https://api-goerli.etherscan.io/api?module=logs&action=getLogs&fromBlock=${SkipShiftStartBlocks[chainId]}&toBlock=latest&address=${SkipShiftContracts[chainId]}&topic0=0xf1bd45f179b5c1692c0bc5e67619b9785e28ac7e0dd0bbf0f6aa72674d12f719&apiKey=PPJX226ZJHTKINMR75JY2YPZJMMGI5Y5JQ`
                )
            ).data.result;
            events = logsDecoder.decodeLogs(events);
            assets = events.map((e: any) => w3.utils.toChecksumAddress(e.events[0].value));
        } else if (chainId == 109) {
            events = (
                await axios.get(
                    `https://www.shibariumscan.io/api/?module=logs&action=getLogs&fromBlock=${SkipShiftStartBlocks[chainId]}&toBlock=latest&address=${SkipShiftContracts[chainId]}&topic0=0xf1bd45f179b5c1692c0bc5e67619b9785e28ac7e0dd0bbf0f6aa72674d12f719&apiKey=PPJX226ZJHTKINMR75JY2YPZJMMGI5Y5JQ`
                )
            ).data.result;
            events = logsDecoder.decodeLogs(events);
            assets = events.map((e: any) => w3.utils.toChecksumAddress(e.events[0].value));
        } else if (chainId == 43114) {
            events = (
                await axios.get(
                    `https://api.snowtrace.io/api?module=logs&action=getLogs&fromBlock=${SkipShiftStartBlocks[chainId]}&toBlock=latest&address=${SkipShiftContracts[chainId]}&topic0=0xf1bd45f179b5c1692c0bc5e67619b9785e28ac7e0dd0bbf0f6aa72674d12f719&apiKey=PPJX226ZJHTKINMR75JY2YPZJMMGI5Y5JQ`
                )
            ).data.result;
            events = logsDecoder.decodeLogs(events);
            assets = events.map((e: any) => w3.utils.toChecksumAddress(e.events[0].value));
        }
    } catch (error) {
        console.log('api error: ', error);
        try {
            events = await skipShift.getPastEvents('LiquidityStatus', {
                filter: { status: 'true' },
                fromBlock: SkipShiftStartBlocks[chainId],
                toBlock: 'latest'
            });
            assets = events.map((e) => e.returnValues.asset);
        } catch (e) {
            try {
                events = (
                    await logChunker(
                        w3,
                        SkipShiftStartBlocks[chainId],
                        'latest',
                        ['0xf1bd45f179b5c1692c0bc5e67619b9785e28ac7e0dd0bbf0f6aa72674d12f719'],
                        SkipShiftContracts[chainId]
                    )
                ).logs;
                events = logsDecoder.decodeLogs(events);
                assets = events.map((e: any) => w3.utils.toChecksumAddress(e.events[0].value));
            } catch (e) {
                return [];
            }
        }
    }
    let t: Set<string> = new Set(assets);
    assets = [...t];

    let tokenData: IToken[] = [];
    let tokenCalls = [];
    for (let i = 0; i < assets.length; i++) {
        const asset = assets[i];
        tokenCalls.push(
            await getTokenInfo(w3, asset, chainId).then((data) => {
                tokenData.push(data[0]);
            })
        );
    }
    // await Promise.allSettled(tokenCalls);

    P2PAssets.set(tokenData);

    return tokenData;
};

// TODO: check for CANCELLED liquidity and remove those from the list
export const getAllAssets = async (chainId: number) => {
    if (!SubGraphs[chainId]) return await getAllAssetsWeb3(chainId);
    else
        try {
            let query = JSON.stringify({
                query: `
    query GetAllAssets {
      liquidityStatuses {
        listingId
        maker
        status
        asset
        blockTimestamp
      }
    }`
            });

            const req: AxiosRequestConfig = {
                method: 'post',
                url: SubGraphs[chainId],
                headers: {
                    'Content-Type': 'application/json'
                },
                data: query
            };

            const result = await axios(req);
            const data: IEventAsset[] = result.data.data.liquidityStatuses as unknown as IEventAsset[];
            let tokens = data.map((d) => d.asset);
            tokens = [...new Set(tokens)];

            let parsed = [];
            for (let i = 0; i < tokens.length; i++) {
                const token = toChecksumAddress(tokens[i]);
                let tokenInfo: IToken[] = [];
                try {
                    tokenInfo = await getTokenInfo(null, token, chainId);
                } catch (e) {}
                if (tokenInfo.length == 0) continue;
                parsed.push(tokenInfo[0]);
            }

            return parsed;
        } catch (e) {
            console.log('subgraph error: ', e);
            return await getAllAssetsWeb3(chainId);
        }
};

export const collateralBalance = async (wallet: string, chainId: number): Promise<BigNumber> => {
    const w3 = await rpcLooper(chainId);
    if (!w3) return BigNumber(0);

    try {
        let skipShift = new w3.eth.Contract(ABI.skipShift, SkipShiftContracts[chainId]) as unknown as SkipShiftCore;
        const token = await skipShift.methods.collateralToken().call();

        const colToken = new w3.eth.Contract(ABI.erc20, token) as unknown as ERC20;
        const colTokenDecimals = await colToken.methods.decimals().call();
        const colBalance = await colToken.methods.balanceOf(wallet).call();
        return BigNumber(colBalance).div(10 ** Number(colTokenDecimals));
    } catch (error) {
        return BigNumber(0);
    }
};

export const fetchFromBunny = async (testing: boolean = false): Promise<{ returnData: IRawListing[]; pairs: any[][]; timestamp: number } | undefined> => {
    // const url = testing ? 'https://live.carbon.financial/skipcachetesting' : 'http://127.0.0.1:8888/skipcache?active=true';
    const url = 'https://skipscan.win/api/skipcache?active=true'; //'https://live.carbon.financial/skipcache';
    let response = await axios.get(url).catch(() => null);

    console.log('fetchFromBunny unfiltered data', response?.data.returnData);

    if (response?.data?.returnData) {
        const filteredData = (response.data.returnData as IRawListing[]).reduce(
            (acc: { returnData: any[]; pairs: any[][] }, listing: IRawListing, index: number) => {
                if (!listing.undercollateralized && +listing.spread < SPREAD_LIMIT) {
                    //remove corresponding pair for any listings that are filtered out so the venues dont continue to show up
                    acc.returnData.push(listing);
                    acc.pairs.push(response?.data.pairs[index]);
                }
                return acc;
            },
            { returnData: [], pairs: [] }
        );

        response.data.returnData = filteredData.returnData;
        response.data.pairs = filteredData.pairs;
    }

    console.log('fetchFromBunny filtered data', response?.data.returnData);
    console.log('fetchFromBunny filtered pairs', response?.data.pairs);
    //FILTER OUT UNDERCOLLATERALIZED LIQ (TIER 3 ONLY)
    // const startTime = performance.now();
    // if (response?.data?.returnData) {
    //     const sortedData: any[] = response.data.returnData.sort((a: IRawListing, b: IRawListing) => a.asset.chainId! - b.asset.chainId!);
    //     const groupedByChainId = sortedData.reduce((acc, listing: IRawListing) => {
    //         const chainId = listing.asset.chainId;
    //         acc[chainId || 0] = acc[chainId || 0] || [];
    //         acc[chainId || 0].push(listing);
    //         return acc;
    //     }, {});
    //     let groupedArray: IRawListing[][] = Object.values(groupedByChainId);
    //     console.log('grouped listings by chainId:', groupedArray);

    //     for(let i = 0; i < groupedArray.length; i++){
    //         for(let j = 0; j < groupedArray[i].length; j++){
    //             try{
    //                 const isActive = await verifyActive(groupedArray[i][j].maker, "3", groupedArray[i][j].asset.chainId || 0);
    //                 console.log('listing for', groupedArray[i][j].asset.symbol, 'on chain', groupedArray[i][j].chainID, 'is active?', isActive);
    //                 if (!isActive) {
    //                     groupedArray[i].splice(j, 1);
    //                     j--;
    //                 }
    //             }catch(e){
    //                 console.error('verifyActive loop error', e);
    //                 continue;
    //             }
    //         }
    //     }
    //     response.data.returnData = groupedArray.flat();
    // }
    // const endTime = performance.now();
    // const timeElapsed = (endTime - startTime) / 1000;
    // console.log(`verifyActive loop took ${timeElapsed.toFixed(2)} seconds`);

    if (!response || !response?.data) return undefined;
    const data = response.data;
    return { returnData: data.returnData, pairs: data.pairs, timestamp: Date.now() };
};

export const fetchActiveLiq = async (
    testing: boolean = false
): Promise<{ returnData: IRawListing[]; minMax: Result; pairs: any[][]; timestamp: number } | undefined> => {
    const liveHost = true; // window.location.hostname.search('skipshift') > -1;
    const availableChains = liveHost ? [1, 56, 43114, 369, 109] : [1, 5, 56, 43114, 369, 109];

    let apiData: SkipShiftData = (await fetchFromBunny(testing)) || { returnData: [], pairs: [], timestamp: 0 };
    if (apiData.timestamp == 0 || apiData.returnData.length == 0) {
        console.error('no data received from bunny server');
    }

    // const chainsInApiData = new Set(apiData?.returnData.map((data) => data.chainID));
    // const missingChains = availableChains.filter((chain) => !chainsInApiData.has(chain));

    // get logoURIs (we should probably do this on the server when polling instead)
    const logoPromises = apiData.returnData.map(async (listing) => {
        if (listing.asset.address && listing.asset.chainId && listing.asset.symbol) {
            listing.asset.logoURI = await getTokenLogo(listing.asset.address, listing.asset.chainId, listing.asset.symbol, listing.asset.logoURI);
        }
    });

    await Promise.all(logoPromises);

    // if (missingChains.length > 0) {
    //   const missingChainsDataPromises = missingChains.map((chain) => processChain(chain));
    //   const missingChainsData = await Promise.allSettled(missingChainsDataPromises);

    //   missingChainsData.forEach((result) => {
    //     if (result.status === 'fulfilled') {
    //       apiData.returnData = [...apiData.returnData, ...(result?.value?.returnData || [])];
    //       apiData.pairs = [...apiData.pairs, ...(result?.value?.pairs || [])];
    //     }
    //   });
    // }

    const minMax = aggregateMinMax(apiData.returnData);
    console.log('minMax: ', minMax);

    P2PDataStore.set({ returnData: apiData.returnData, pairs: apiData.pairs, minMax: minMax, timestamp: Date.now() });

    return { returnData: apiData.returnData, pairs: apiData.pairs, minMax: minMax, timestamp: Date.now() };
};

const processChain = async (chain: number): Promise<{ returnData: IRawListing[]; pairs: any[][] }> => {
    const w3 = await rpcLooper(chain);
    if (!w3) return { returnData: [], pairs: [] };

    const skipShift = new w3.eth.Contract(ABI.skipShift, SkipShiftContracts[chain]);
    const assets = await getAllAssets(chain);
    if (!assets) return { returnData: [], pairs: [] };

    // const listingsAndPairs = await Promise.allSettled(assets.map(asset => processAsset(asset, skipShift, chain)));

    const returnData: IRawListing[] = [];
    const pairs: any[][] = [];
    let processedListings: IRawListing[] = [];

    assets.forEach(async (asset, index) => {
        console.log('in foreach');
        if (!asset || !asset?.address) return;
        console.log('processing asset: ', asset);
        let datum = await processAsset(asset, skipShift, chain).catch((e) => {
            console.log('error processing asset: ', e.message);
            return { returnData: [], pairs: [] };
        });

        // if(!datum) return;
        returnData.push(...datum.returnData);
        pairs.push(...datum.pairs);
        // P2PDataStore.update({ returnData, pairs, timestamp: Date.now() })
    });

    // listingsAndPairs.forEach(result => {
    //   if (result.status === "fulfilled") {
    //     returnData.push(...(result.value?.returnData || []));
    //     pairs.push(...(result.value?.pairs || []));
    //   }
    // });

    return { returnData, pairs };
};

const processAsset = async (assetInfo: any, skipShift: any, chain: number): Promise<{ returnData: IRawListing[]; pairs: any[][] }> => {
    let listings;
    try {
        listings = await skipShift.methods.getAssetListings(0, assetInfo.address).call();
    } catch (e) {
        console.log('error getting asset listings: ', e);
        return { returnData: [], pairs: [] };
    }
    // listings = await skipShift.methods.getAssetListings(0, assetInfo.address).call();
    // const asset = (await getTokenInfo(w3, assetInfo.address!, chain))[0];

    const returnData: IRawListing[] = [];
    const pairs: any[][] = [];

    listings[0].forEach((listing: IRampDetails, index: number) => {
        if (!listing.active) return;

        const temp: IRawListing = {
            id: index,
            active: listing.active,
            reqCollateral: listing.reqCollateral,
            type: listing.buyOrSell as IRawListing['type'],
            assetType: listing.assetType as IRawListing['assetType'],
            tier: listing.tier as IRawListing['tier'],
            spread: listing.spread,
            currency: hexToUtf8(listing.currency),
            asset: assetInfo,
            min: listing.min,
            max: listing.max,
            maker: listing.maker,
            venues: listings[1][index],
            chainID: chain
        };

        if (temp.active != false) {
            returnData.push(temp);
            pairs.push([assetInfo.symbol, hexToUtf8(listing.currency), temp.venues, temp.type]);
        }
        // P2PDataStore.update({ returnData, pairs, timestamp: Date.now() })
    });

    return { returnData, pairs };
};

export const hasCompleted = (status: number | undefined) => {
    if (status === undefined || status === null) return [1, 4, 5, 10, 11, 12].includes(Number(status || 0));
    else return [1, 4, 5, 10, 11, 12].includes(Number(status|| 0));
};

export const getTokenDecimals = async (tokenAddress: string, chainId: number) => {
    if (tokenAddress === Contracts.BASE) return 18;
    const web3 = await rpcLooper(chainId);
    if (!web3) return null;
    const erc20 = new web3.eth.Contract(ABI.erc20, tokenAddress) as unknown as ERC20;
    const decimals = await erc20.methods.decimals().call();
    return decimals;
};

export const changeLiqStatus = async (data: IP2PTradeLiq, activate: boolean = false) => {
    // console.log('halting p2p listing...');
    const w3 = get(web);
    let chainId = get(ChainId);
    let options: ITransactionOptions = get(TransactionOptions);

    let skipShift = new w3.eth.Contract(ABI.skipShift, SkipShiftContracts[chainId]);

    const status = activate ? true : false;

    addActionLogLine(ActionLogTexts[get(Locale)].disabling_liquidity);

    await new Promise((resolve, reject) => {
        skipShift.methods
            .listingStatus(data.id, status, data.asset.address)
            .send({
                from: data.maker
            })
            .on('transactionHash', (_hash: string) => {
                addActionLogLine(ActionLogTexts[get(Locale)].processing_transaction);
                // txHash = hash;
            })
            .on('receipt', function (receipt: any) {
                // console.log('receipt: ', receipt);
                // console.log('listing halted');
                addActionLogLine(
                    ActionLogTexts[get(Locale)].p2p_liquidity_status_change +
                        (activate ? ActionLogTexts[get(Locale)].activated : ActionLogTexts[get(Locale)].paused)
                );

                trackEvent('liquidity', 'status_change', '', activate ? 'activated' : 'paused');
                resolve(receipt);
            });
    });
};

export const createP2pLiquidity = async (data: IP2PLiquidity, userInfo: IUserInfo) => {
    // console.log('creating p2p liquidity...');
    let chainId = get(ChainId);
    const w3 = await rpcLooper(chainId);
    if (!w3) return;
    let wallet = get(Wallet);
    if (!wallet) return false;

    console.log('chain from web3: ', await w3?.eth.getChainId());

    let options: ITransactionOptions = get(TransactionOptions);

    console.log('SkipShiftContracts[chainId]: ', SkipShiftContracts[chainId]);
    const skipShift = new w3.eth.Contract(ABI.skipShift, SkipShiftContracts[chainId]) as unknown as SkipShiftCore;

    console.log('venues', data.venues);
    data.venues.forEach((venue) => {
        const identHash = w3?.utils.soliditySha3(venue.identHash);
        if (identHash) {
            venue.identHash = identHash;
        }
    });

    console.log('data: ', data);

    let tier = 3;
    if (data.tier == 'LIMITED') tier = 0;
    else if (data.tier == 'PROFESSIONAL') tier = 3;

    let liqdirection;
    if (data.type == 'OFFRAMP') liqdirection = 0;
    else liqdirection = 1;

    let assetType;
    if (data.assetType == 'NATIVE') assetType = 0;
    else if (data.assetType == 'ERC20') assetType = 1;
    else if (data.assetType == 'NFT') assetType = 2;
    else assetType = -1;

    if(assetType == -1) return;

    let currencyCode = toHex(data.currency?.symbol || 'USD');

    console.log('createLiq currencyCode', currencyCode);

    console.log('calling collateralToken');

    let collateralTokenAddress = await skipShift.methods.collateralToken().call();
    let collateralTokenContract = new w3.eth.Contract(ABI.erc20, collateralTokenAddress) as unknown as ERC20;
    console.log('collateralToken', collateralTokenAddress);

    //check collat token bal
    let professionalLiqTierCollatAmt = await skipShift.methods.tierThresholds(3).call();
    console.log('professional liq tier collateral amount', professionalLiqTierCollatAmt);
    let userCollatTokenWalletBalance = await collateralTokenContract.methods.balanceOf(wallet.address).call();
    let autoCollateralize = BigNumber(professionalLiqTierCollatAmt).gte(BigNumber(userCollatTokenWalletBalance));
    //must check if provider is collateralized or not already
    if (BigNumber(userInfo.collateral).gte(BigNumber(professionalLiqTierCollatAmt))) autoCollateralize = false;

    //check exemption status independent from threshold amts, override autocollat if exempt
    //TODO: FIX THIS
    // const exemptFromCollat = await skipShift.methods.whitelistedProvider(wallet.address).call();
    // if(exemptFromCollat) autoCollateralize = false;
    console.log('sufficient collateral token balance?', autoCollateralize);

    //check collateral approval
    // console.log('checking collateral approval: ', wallet.address, collateralTokenAddress, SkipShiftContracts[chainId], chainId);
    let collatApprovalNeeded = await checkApproval(
        wallet.address,
        collateralTokenAddress,
        SkipShiftContracts[chainId],
        chainId,
        BigNumber(professionalLiqTierCollatAmt)
    );
    // console.log('collateral approval needed?', collatApprovalNeeded);
    let nativeForCollat = BigNumber(0);

    //do not request an approval if the liq tier amount is zero!!!
    //approve collat (in wallet) if needed and not autocollatting
    if (!autoCollateralize && collatApprovalNeeded && BigNumber(professionalLiqTierCollatAmt).gt(0)) {
        addActionLogLine('Approving collateral token');
        try {
            if (collateralTokenContract) {
                await collateralTokenContract.methods.approve(SkipShiftContracts[chainId], professionalLiqTierCollatAmt).send({ from: wallet.address });
                addActionLogLine('Collateral token Approved!');
            } else {
                throw new Error('invalid erc20');
            }
        } catch (e) {
            // console.log('error: ', e);
            addActionLogLine('Error approving collateral token');
            return false;
        }
    } else if (autoCollateralize) {
        //autocollateralize case
        //determine native needed for swap
        // get quote for collateral
        const base = await getTokenInfo(w3, Contracts.BASE, chainId, true);
        const collateralToken = await getTokenInfo(w3, collateralTokenAddress, chainId, true);
        const quote = await getPriceQuote(BigNumber(professionalLiqTierCollatAmt), base[0], collateralToken[0], chainId, true);
        console.log('neededCollat quote: ', quote);

        if (!quote || !base[0]?.decimals) {
            TxError.set(ActionLogTexts[get(Locale)].could_not_get_collateral_quote);
            return false;
        }

        nativeForCollat = quote.leftAmount
            .times(10 ** base[0].decimals)
            .times(1.01)
            .dp(0);
        console.log('nativeForCollat: ', nativeForCollat.toString());
    } //else do nothing if no collateral is required due to pre existing collat

    addActionLogLine('Sending liquidity transaction...');

    let rcpt;
    let txHash: any;
    let checkTx = false;

    console.log('kypString test', toHex(data.kypString));
    console.log('kypString test', hexToUtf8(ascii_to_hexa(data.kypString)));

    console.log(
        'submitting liquidity with details:',
        data.reqCollateral || false,
        tier,
        liqdirection,
        assetType,
        currencyCode,
        [data.min, data.max, data.spread],
        data.asset.address || '',
        data.taker ? data.taker : '0x0000000000000000000000000000000000000000',
        data.venues as unknown as [string, string][],
        data.kypString
    );
    let convertedVenues: [string, string][] = [];
    data.venues.forEach((venue) => {
        const convertedVenue: [string, string] = [venue.platform, venue.identHash];
        convertedVenues.push(convertedVenue);
    });

    // if(!tier || !assetType){
    //     console.log('add liq e: missing data');
    //     return
    // }

    try {
        const gas = await skipShift.methods
            .addRampLiquidity(
                data.reqCollateral || false,
                tier || 3,
                liqdirection,
                assetType,
                currencyCode,
                [data.min, data.max, data.spread],
                data.asset.address || '',
                data.taker ? data.taker : '0x0000000000000000000000000000000000000000',
                convertedVenues,
                toHex(data.kypString) || ''
            )
            .estimateGas({
                from: wallet.address,
                value: autoCollateralize ? nativeForCollat.toFixed(0) : '0'
            });

        await skipShift.methods
            .addRampLiquidity(
                data.reqCollateral || false,
                tier,
                liqdirection,
                assetType,
                currencyCode,
                [data.min, data.max, data.spread],
                data.asset.address || '',
                data.taker ? data.taker : '0x0000000000000000000000000000000000000000',
                convertedVenues,
                toHex(data.kypString) || ''
            )
            .send({
                from: wallet.address,
                value: autoCollateralize ? nativeForCollat.toFixed(0) : '0',
                gas: gas
            })
            .on('transactionHash', (hash: string) => {
                addActionLogLine(ActionLogTexts[get(Locale)].processing_transaction);
                txHash = hash;
            })
            .on('receipt', function (receipt) {
                // console.log('receipt: ', receipt);
                addActionLogLine('P2P liquidity created!');
                rcpt = receipt;
            })
            .on('error', function (error: any) {
                // console.log('error', error);
                if (error.code === 4001) addActionLogLine(ActionLogTexts[get(Locale)].transaction_rejected);
                else if (error.code === -32000) checkTx = true;
                else addActionLogLine(ActionLogTexts[get(Locale)].error_encountered);

                if (!checkTx) return false;
            });

        if (checkTx) {
            addActionLogLine(ActionLogTexts[get(Locale)].rpc_janky_checking);
            let txStatus = await w3?.eth.getTransactionReceipt(txHash);
            if (txStatus?.status) {
                if (txStatus.status) {
                    addActionLogLine('P2P liquidity initiated!');
                    rcpt = txStatus;
                    trackEvent('liquidity', 'create', data.type, data.asset.symbol || '');
                    return true;
                } else {
                    addActionLogLine('P2P liquidity tx failed :(');
                    return false;
                }
            } else return false;
        } else return true;
    } catch (e) {
        console.log(e);
        return false;
    }
};

export const acceptTrade = async (trade: IP2PTrade, providerVenueId: string): Promise<boolean> => {
    if (
        !trade.asset.chainId || 
        !trade.asset.address || 
        !trade.asset.decimals || 
        !trade.amount || 
        !trade.venue || 
        !trade.maker
    ) return false;

    const w3 = await rpcLooper(trade.asset.chainId);
    const wallet = get(Wallet);
    if (!w3) return false;
    const skipShift = new w3.eth.Contract(ABI.skipShift, SkipShiftContracts[trade.asset.chainId]) as unknown as SkipShiftCore;

    //check balances
    let erc20;
    if(trade.asset.address.toLowerCase() !== Contracts.BASE.toLowerCase()){//trading tokens
        erc20 = new w3.eth.Contract(ABI.erc20, trade.asset.address) as unknown as ERC20;
        let erc20Balance = BigNumber(await erc20.methods.balanceOf(trade.maker).call());
        console.log('acceptance erc20 balance', erc20Balance.toString(), BigNumber(trade.amount).toString())
        if(BigNumber(trade.amount).gt(erc20Balance.div(10**trade.asset.decimals))){
            addActionLogLine(
                ActionLogTexts[get(Locale)].insufficient_funds_for_trade_1 +
                `${trade.asset.symbol}` +
                ActionLogTexts[get(Locale)].insufficient_funds_for_trade_2
            );
            //set dex preload, show modal
            console.log('accept trade amount', trade.amount.toString())
            DEXPreload.set({ 
                spending_chain_id: trade.asset.chainId, 
                spending_token_address: '', 
                spending_token_symbol: '',
                receiving_chain_id: trade.asset.chainId, 
                receiving_token_address: trade.asset.address,
                receiving_token_symbol: trade.asset.symbol || '',
                trade_size: BigNumber(trade.amount),
                wallet_balance: erc20Balance.div(10**trade.asset.decimals)
            });
            showDEXModal.set(true);
            return false;
        }
    }else{//trading native
        let nativebal = await w3.eth.getBalance(trade.maker);
        if(BigNumber(trade.amount).gt(BigNumber(nativebal))){
            addActionLogLine(
                ActionLogTexts[get(Locale)].insufficient_funds_for_trade_1 +
                `${trade.asset.symbol}` +
                ActionLogTexts[get(Locale)].insufficient_funds_for_trade_2
            );
            return false;
        }
    }

    const approvalNeeded =
        trade.type == 'ONRAMP'
            ? await checkApproval(
                  wallet.address,
                  trade.asset.address,
                  SkipShiftContracts[trade.asset.chainId],
                  trade.asset.chainId,
                  BigNumber(trade.amount).times(10 ** trade.asset.decimals)
              )
            : false;
    if (approvalNeeded && erc20) {
        addActionLogLine('Approving ' + trade.asset.symbol);

        let gas: any;
        try {
            gas = await erc20.methods
                .approve(
                    SkipShiftContracts[trade.asset.chainId],
                    BigNumber(trade.amount)
                        .times(10 ** trade.asset.decimals)
                        .toFixed(0)
                )
                .estimateGas({ from: trade.maker });
            console.log('gas', gas);
        } catch (e) {
            console.log('acceptTrade err:', e);
        }
        if (!gas) {
            addActionLogLine('Error approving tokens');
            return false;
        }
        // console.log('acceptTrade gas', gas);
        await erc20.methods
            .approve(
                SkipShiftContracts[trade.asset.chainId],
                BigNumber(trade.amount)
                    .times(10 ** trade.asset.decimals)
                    .toFixed(0)
            )
            .send({ from: wallet.address, gas: gas });
        addActionLogLine(trade.asset.symbol + ' Approved!');
    }
    const totalCost: BigNumber = trade.totalCost && BigNumber(trade.totalCost).gt(0) ? BigNumber(trade.totalCost) : await calcTotalCost(trade);
    console.log('calculated total cost', totalCost.toString());
    console.log('trade amount', trade.amount);

    //LOADZ
    const counterparty = wallet.address.toLowerCase() === trade.maker?.toLowerCase() ? trade.taker : trade.maker;
    if (!counterparty) return false;
    const venue: IP2PVenue = { platform: trade.venue.platform, makerId: providerVenueId, identHash: w3?.utils.soliditySha3(providerVenueId) || '' };
    const pload = await buildPayload(String(trade.tradeId), venue);
    if (!pload) return false;
    const loadDropped = await submitEncryptedPayload(String(trade.tradeId), counterparty, pload);
    if (!loadDropped) {
        addActionLogLine(ActionLogTexts[get(Locale)].could_not_submit_encrypted_payload);
        addActionLogLine(ActionLogTexts[get(Locale)].transaction_failed);
        return false;
    }
    //END LOADZ

    //CONTRACT INTERACTIONS
    if(!trade.tradeId) return false;

    let acceptTx: any;
    try {
        if (trade.type == 'OFFRAMP' || trade.asset.address?.toLowerCase() !== Contracts.BASE.toLowerCase()) {
            console.log('do not need to send value with tx');
            console.log('responding ramp with details:', true, trade.tradeId, totalCost.toFixed(0), wallet.address);
            let gas = await skipShift.methods.respondRamp(true, trade.tradeId, totalCost.toFixed(0)).estimateGas({ from: wallet.address });
            acceptTx = await skipShift.methods.respondRamp(true, trade.tradeId, totalCost.toFixed(0)).send({ from: wallet.address, gas: gas });
        } else {
            const convertedAmount = BigNumber(trade.amount)
                .times(10 ** trade.asset.decimals)
                .toString();
            console.log('converted amount', convertedAmount);
            let gas = await skipShift.methods
                .respondRamp(true, trade.tradeId, totalCost.toFixed(0))
                .estimateGas({ from: wallet.address, value: convertedAmount });
            acceptTx = await skipShift.methods
                .respondRamp(true, trade.tradeId, totalCost.toFixed(0))
                .send({ from: wallet.address, value: convertedAmount, gas: gas });
        }
    } catch (e) {
        console.log(e);
        if (String(e).includes('transfer amount exceeds'))
            addActionLogLine(
                ActionLogTexts[get(Locale)].insufficient_funds_for_trade_1 +
                    `${trade.asset.symbol}` +
                    ActionLogTexts[get(Locale)].insufficient_funds_for_trade_2
            );
    }
    //END CONTRACT INTERACTIONS

    if (acceptTx) {
        trackEvent(trade.type, 'accept', trade?.currency?.symbol || '', trade?.amount || '');
        return true;
    } else return false;
};

export const rejectTrade = async (trade: IP2PTrade): Promise<boolean> => {
    if (!trade.asset.chainId || !trade.tradeId) return false;
    const w3 = await rpcLooper(trade.asset.chainId);
    const wallet = get(Wallet);
    if (!w3) return false;
    const skipShift = new w3.eth.Contract(ABI.skipShift, SkipShiftContracts[trade.asset.chainId]) as unknown as SkipShiftCore;

    try {
        let gas = await skipShift.methods.respondRamp(false, trade.tradeId, '1').estimateGas({ from: wallet.address });
        let tx = await skipShift.methods.respondRamp(false, trade.tradeId, '1').send({ from: wallet.address, gas: gas });
    } catch (e) {
        console.log(e);
        return false;
    }

    trackEvent(trade.type, 'reject', trade?.currency?.symbol || '', trade?.amount || '');

    return true;
};

//this is only for if the trade doesnt have a totalCost in the list for whatever reason
const calcTotalCost = async (trade: IP2PTrade): Promise<BigNumber> => {
    const listings = get(AllLiquidityStore);
    if (!trade.listingId) return BigNumber(0);

    const matchedListing = listings.returnData.find(
        (listing: IRawListing) =>
            listing.id == trade.listingId &&
            listing.chainID == trade.asset.chainId &&
            trade.asset.address?.toLowerCase() == listing.asset.address?.toLowerCase()
    );
    console.log('matchedListing', matchedListing);

    const spread = matchedListing?.spread;
    if (!trade.listingId || !trade.amount || !trade.asset.chainId || !spread) return BigNumber(0);

    let stable: IToken = chains[trade.asset.chainId || 0].stableCoins[0];

    let rate: BigNumber | undefined;

    const amt = BigNumber(trade.amount);

    if (trade.type === 'ONRAMP') rate = (await getPriceQuote(amt, stable, trade.asset, trade.asset.chainId))?.leftAmount;
    else rate = (await getPriceQuote(amt, trade.asset, stable, trade.asset.chainId))?.rightAmount;
    if (!rate) return BigNumber(0);
    console.log('rate in respondRamp', rate.toFixed());

    let totalCost = trade.type == 'ONRAMP' ? rate.plus(rate.times(spread).div(DIVISOR)) : rate.minus(rate.times(spread).div(DIVISOR));

    if (trade.currency?.symbol !== 'USD') {
        totalCost = totalCost.times(await getRate(trade.currency?.symbol || ''));
    }

    console.log('final rounded cost', totalCost.toFixed());
    return totalCost;
};

export async function getTierThreshold(chainId: number, tier: number): Promise<BigNumber> {
    const w3 = await rpcLooper(chainId);
    if (!w3) return BigNumber(0);

    let skipShift = new w3.eth.Contract(ABI.skipShift, SkipShiftContracts[chainId]) as unknown as SkipShiftCore;

    let collatTokenAddy = await skipShift.methods.collateralToken().call();
    let collatToken = new w3.eth.Contract(ABI.erc20, collatTokenAddy) as unknown as ERC20;
    let decimals = await collatToken.methods.decimals().call();
    let tierThreshold = await skipShift.methods.tierThresholds(tier).call();

    return BigNumber(tierThreshold).div(10 ** +decimals);
}

export const getBaseFee = async (): Promise<number> => {
    const chainId = get(ChainId) || 1;
    const w3 = await rpcLooper(chainId);
    if (!w3) return 1;

    let skipShift = new w3.eth.Contract(ABI.skipShift, SkipShiftContracts[chainId]) as unknown as SkipShiftCore;

    let baseFee = await skipShift.methods.baseFee().call();
    let divisor = await skipShift.methods.DIVISOR().call();
    let multiplier = BigNumber(1).minus(BigNumber(baseFee).div(divisor));

    console.log('baseFee multiplier', multiplier);
    return BigNumber(multiplier).toNumber();
}
