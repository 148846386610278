<script lang="ts">
    import { IToken } from '../../Data/Tokens';
    import TokenList from '../../components/Common/TokenList.svelte';
    import { IP2PLiquidity, IP2PVenue, IP2PTradeLiq, currencies, paymentMethods, tokens, paymentMethodsMap, IUserInfo } from '../../Data/P2P';
    import { MultiSelect } from 'svelte-multiselect';
    import { hexToRgba } from '../../API/helpers/hexToRGBA';
    import { WalletConnected, Wallet, ChainId } from '../../Data/Wallet';
    import SwitchComponent from '../../components/Common/SwitchComponent.svelte';
    import Contracts from '../../Data/Contracts';
    import { createP2pLiquidity, getTierThreshold } from '../../API/P2P';
    import BigNumber from 'bignumber.js';
    import { ActionLogTexts, AddLiqTexts, Locale, SkipTexts } from '../../Data/Locale';
    import { onMount } from 'svelte';
    import { addActionLogLine } from '../../Data/ActionLogs';
    import { SPREAD_LIMIT } from '../../Data/Constants';
    import chains from '../../Data/Chains';

    let neededCollat: BigNumber;
    let selectedToken: IToken;
    let selectedCurrency: IToken;
    let direction: 'OFFRAMP' | 'ONRAMP' = 'ONRAMP';
    let showTokenList: boolean = false;
    let showCurrencyList: boolean = false;
    let requireCollateral: boolean = true;
    let requireKYP = false;
    let kypInputFields: string[] = [];

    let collateralText = '<i class="fad fa-lock text-green-400 fa-stack-1x"></i>';

    // export let buttonGradient: string;
    // export let bgColor: string;
    // export let textLabelColor: string;
    export let userInfo: IUserInfo | undefined;

    let selectedVenues: any[] = [];
    let venuePlaceholder = SkipTexts[$Locale].select_venue;
    let spread: string;
    let min: string = '';
    let max: string = '';
    let taker: string;

    $: min = min.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');
    $: max = max.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');

    function enforceMaxGreaterThanMin() {
        const numericMin = parseFloat(min) || 0;
        const numericMax = parseFloat(max) || 0;

        // only adjust max if it's less than or equal to min
        if (numericMax <= numericMin) {
            max = (numericMin + 0.01).toFixed(2);
        }
    }

    const onShowTokenListClick = () => {
        // dispatch('showTokenList');
        showTokenList = true;
    };
    const onShowCurrencyListClick = () => {
        // dispatch('showTokenList');
        showCurrencyList = true;
    };

    const buildTokenList = () => {};

    const onAddKYPFieldClick = () => {
        kypInputFields.push('');
        kypInputFields = [...kypInputFields];
    };

    const onToggleKYPInputs = () => {
        if (requireKYP) {
            if (kypInputFields.length == 0) {
                kypInputFields.push('');
                kypInputFields = [...kypInputFields]; //stupit svelt thang
            }
        } else {
            kypInputFields = [];
        }
    };

    const removeKYPInput = () => {
        kypInputFields.pop();
        kypInputFields = [...kypInputFields];
    };

    function filterNumericInput(event: Event) {
        const inputElement = event.target as HTMLInputElement;

        if (inputElement.value.split('')[inputElement.value.split('').length - 1] == '.') return;
        let numericValue = parseFloat(inputElement.value.replace(/[^\d.-]/g, ''));

        if (numericValue < 1) {
            numericValue = 1;
        } else if (numericValue > 100) {
            numericValue = 100;
        }

        inputElement.value = isNaN(numericValue) ? '' : numericValue.toString();
        spread = inputElement.value;
    }

    const onSubmitClick = async () => {
        const assetType = selectedToken?.address?.toLowerCase() == Contracts.BASE.toLowerCase() ? 'NATIVE' : 'ERC20';
        
        if (!selectedToken?.decimals) {
            addActionLogLine(ActionLogTexts[$Locale].select_token);
            return;
        }
        if (selectedToken.chainId !== $ChainId){
            addActionLogLine(`Invalid asset network. Please switch wallet to ${chains[selectedToken.chainId || 0].shortName} to create a listing for this asset.`);
            return;
        }
        if (+min > +max) {
            addActionLogLine('Min cannot be greater than max!');
            return;
        }
        if (!min) {
            addActionLogLine(ActionLogTexts[$Locale].enter_min);
            return;
        }
        if (!max) {
            addActionLogLine(ActionLogTexts[$Locale].enter_max);
            return;
        }
        if (!spread) {
            addActionLogLine(ActionLogTexts[$Locale].enter_spread);
            return;
        }
        if (+spread * 100 > SPREAD_LIMIT) {
            addActionLogLine(`${ActionLogTexts[$Locale].lower_spread}${SPREAD_LIMIT / 100}%`);
            return;
        }
        if (!selectedCurrency) {
            addActionLogLine(ActionLogTexts[$Locale].select_currency);
            return;
        }
        if (selectedVenues?.length == 0) {
            addActionLogLine(ActionLogTexts[$Locale].select_venues);
            return;
        }
        if (kypInputFields?.some((item) => item === '' || !item)) {
            addActionLogLine(ActionLogTexts[$Locale].blank_kyp_warning);
            return;
        }

        const venues: IP2PVenue[] = [];

        selectedVenues.forEach((venue, index) => {
            venues.push({ platform: venue, identHash: '' }); //send empty string for identHash (unused prior to trade inits)
        });

        const data: IP2PLiquidity = {
            active: true,
            reqCollateral: requireCollateral,
            type: direction,
            asset: selectedToken,
            assetType: assetType,
            currency: selectedCurrency,
            spread: BigNumber(spread).times(100).decimalPlaces(0).toString(),
            min: BigNumber(min)
                .times(10 ** selectedToken.decimals)
                .toString(),
            max: BigNumber(max)
                .times(10 ** selectedToken.decimals)
                .toString(),
            tier: 'PROFESSIONAL',
            maker: $Wallet.address,
            venues: venues,
            kypString: kypInputFields.join('::')
        };

        console.log('adding liq with data', data);
        if (!userInfo) {
            console.error('userInfo not available');
            return;
        }
        await createP2pLiquidity(data, userInfo);
    };

    const updateKYPInfoSegmented = (val: string, i: number) => {
        console.log('updating kyp info');
        kypInputFields[i] = val;
        console.log('updated kyp', kypInputFields);
    };

    onMount(async () => {
        if ($WalletConnected) {
            await updateNeededCollat();
        }
    });

    const updateNeededCollat = async () => {
        neededCollat = await getTierThreshold($ChainId, 3);
        console.log('collateral needed for listing:', neededCollat.toString());
    };
</script>

<div class="addLiquidityRoot">
    <div class="flex mx-4">
        <button
            class={`mr-2 px-4 py-1 rounded-3xl no-tooltip-border ${direction == "ONRAMP" ? 'buttonGradient text-white' : 'buttonTextLabel'}`}
            data-tooltip={AddLiqTexts[$Locale].onramp_tt}
            on:click={() => (direction = 'ONRAMP')}>
            {AddLiqTexts[$Locale].onramp}
        </button>
        <button
            class={`mr-2 px-4 py-1 rounded-3xl no-tooltip-border ${direction == "OFFRAMP" ? 'buttonGradient text-white' : 'buttonTextLabel'} `}
            data-tooltip={AddLiqTexts[$Locale].offramp_tt}
            on:click={() => (direction = 'OFFRAMP')}>
            {AddLiqTexts[$Locale].offramp}
        </button>
        <button
            class="flex items-center justify-center text-white rounded-lg py-2 px-8 buttonGradient buttonTextLabel"
            on:click={onShowTokenListClick}>
            {#if selectedToken}
                <img class="w-4 mr-1" src={selectedToken.logoURI} alt="" />
            {/if}
            <span class="text-xs">
                {selectedToken?.symbol || AddLiqTexts[$Locale].select_token}
            </span>
        </button>
    </div>
    <div class="flex pt-4 mx-4">
        <button
            class="flex min-w-[8em] max-h-[2rem] justify-center items-center text-white rounded-lg py-2 mr-2 px-4 buttonGradient"
            on:click={onShowCurrencyListClick}>
            <div class="flex items-center">
                {#if selectedCurrency}
                    <img class="w-4 mr-1" src={selectedCurrency.logoURI} alt="" />
                {/if}
                <span class="text-xs">
                    {selectedCurrency?.symbol || AddLiqTexts[$Locale].select_currency}
                </span>
            </div>
        </button>
        <div class="flex rounded-lg text-white" style="background-color: #dfe2e6; border-color: transparent; color: #3e3e3e;">
            <MultiSelect
                bind:selected={selectedVenues}
                options={Object.values(paymentMethods)
                    .map((pm) => pm.shortName)
                    .sort((a, b) => (a?.toLowerCase() || "").localeCompare(b?.toLowerCase() || ""))}
                maxSelect={5}
                allowUserOptions={false}
                placeholder={venuePlaceholder} />
        </div>
    </div>
    <div class="flex flex-col">
        <div class="flex">
            <div
                class="flex flex-col pt-4 mx-4 no-tooltip-border"
                data-tooltip={`${AddLiqTexts[$Locale].spread_tt_1} ${direction == 'ONRAMP' ? AddLiqTexts[$Locale].above : AddLiqTexts[$Locale].below} ${AddLiqTexts[$Locale].spread_tt_2} ${selectedCurrency && selectedToken ? `${AddLiqTexts[$Locale].for} ${selectedToken.symbol}/${selectedCurrency.symbol}` : ''} ${AddLiqTexts[$Locale].spread_tt_3}`}>
                <span style="color: var(--input-text-label);">{AddLiqTexts[$Locale].spread}:</span>
                <div class="bg-gray-300 flex items-center bg-opacity-[70%] w-[10em] mt-1 rounded-tl-lg rounded-bl-2xl rounded-tr-2xl text-white">
                    <input bind:value={spread} on:input={filterNumericInput} class="flex-1 w-full px-4 py-2 text-black" />
                    <span class="mr-4 text-black">%</span>
                </div>
            </div>
            <div class="flex flex-col pt-4 mx-4 no-tooltip-border" data-tooltip={AddLiqTexts[$Locale].req_collateral_tt}>
                <span class="pb-2" style="color: var(--input-text-label);">{AddLiqTexts[$Locale].require_collateral}</span>
                <div class="flex items-center">
                    <SwitchComponent bind:checked={requireCollateral} design="slider" label=""></SwitchComponent>
                    {#if requireCollateral}
                        <i class="ml-4 fa-solid fa-lock text-green-400" />
                    {:else}
                        <span class="ml-4 fa-stack text-xs fa-2x">
                            {@html collateralText}
                            <i class="fad fa-ban fa-stack-2x" style="color:Tomato"></i>
                        </span>
                    {/if}
                </div>
            </div>
        </div>
        <div class="flex mx-4 pt-1">
            <div
                class="flex flex-col mr-2 no-tooltip-border"
                data-tooltip={`${AddLiqTexts[$Locale].min_tt_1} ${selectedToken ? selectedToken.symbol : AddLiqTexts[$Locale].crypto} ${AddLiqTexts[$Locale].min_tt_2}`}>
                <span style="color: var(--input-text-label);">{AddLiqTexts[$Locale].min} {selectedToken ? selectedToken.symbol : ''}:</span>
                <div class="bg-gray-300 flex items-center bg-opacity-[70%] w-[10em] mt-1 rounded-tl-lg rounded-bl-2xl rounded-tr-2xl text-white">
                    <input bind:value={min} class="flex-1 w-full px-4 py-2 text-black" />
                </div>
            </div>
            <div
                class="flex flex-col no-tooltip-border"
                data-tooltip={`${AddLiqTexts[$Locale].max_tt_1} ${selectedToken ? selectedToken.symbol : AddLiqTexts[$Locale].crypto} ${AddLiqTexts[$Locale].max_tt_2}`}>
                <span style="color: var(--input-text-label);">{AddLiqTexts[$Locale].max} {selectedToken ? selectedToken.symbol : ''}:</span>
                <div class="bg-gray-300 flex items-center bg-opacity-[70%] w-[10em] mt-1 rounded-tl-lg rounded-bl-2xl rounded-tr-2xl text-white">
                    <input on:blur={enforceMaxGreaterThanMin} bind:value={max} class="flex-1 w-full px-4 py-2 text-black" />
                </div>
            </div>
        </div>
        <!-- <div class="flex flex-col mx-4 pt-1 no-tooltip-border" data-tooltip={AddLiqTexts[$Locale].taker_tt}>
            <span class="buttonTextLabel">{AddLiqTexts[$Locale].taker}</span>
            <div class="bg-gray-300 flex items-center bg-opacity-[70%] w-full mt-1 rounded-tl-lg rounded-bl-2xl rounded-tr-2xl text-white">
                <input bind:value={taker} class="flex-1 w-full px-4 py-2 text-black" />
            </div>
        </div> -->

        <div class="flex flex-wrap px-2 md:px-4 items-center mt-4">
            <div class="flex flex-col md:mr-[5.4em]">
                <span style="color: var(--input-text-label)">{'Require KYP'}</span>
                <div class="flex items-center no-tooltip-border" data-tooltip={AddLiqTexts[$Locale].kyp_tt}>
                    <SwitchComponent on:clicked={onToggleKYPInputs} bind:checked={requireKYP} design="slider" label=""
                    ></SwitchComponent>
                </div>
            </div>
            {#each kypInputFields as field, i}
                <div
                    class="kyp-input-container bg-gray-300 flex items-center bg-opacity-[70%] w-[10em] mt-2 rounded-tl-lg mx-2 rounded-bl-2xl rounded-tr-2xl text-white">
                    <input placeholder="KYP Option" on:input={(e) => updateKYPInfoSegmented(e.currentTarget?.value, i)} class="flex-1 w-full px-4 py-2 text-black" />
                    {#if kypInputFields.length > 1 && i === kypInputFields.length - 1}
                        <button on:click={removeKYPInput} class="remove-button text-black">
                            <i class="fad fa-trash" style="color:Tomato"></i>
                        </button>
                    {/if}
                </div>
            {/each}
            <button on:click={onAddKYPFieldClick} class:hidden={kypInputFields.length == 0 || kypInputFields.length >= 3}>
                <i class="fad fa-plus text-lg font-bold pl-2 flex items-center px-2 buttonGradient rounded-lg buttonTextLabel"></i>
            </button>
        </div>
    </div>

    <div>
        {#if neededCollat && neededCollat.gt(0)}
            <div class="action-logs symbol-box text-xs font-semibold mr-0 w-fitrounded-3xl py-1 px-4 mt-3 md:mt-0 text-sky-500">
                <span>
                    <!-- Most SkipShift transactions require both parties to be collateralized in order to ensure user safety. 
                If you do not have any trades in progress, you may withdraw your collateral (decollateralize) or maintain it for future trades. 
                All collateral is securely vaulted within the contract. -->
                    <i class="fad fa-info-square text-lg text-green-400 pr-1"></i>
                    {AddLiqTexts[$Locale].collat_warning_1} <span class="">{neededCollat} RBX {AddLiqTexts[$Locale].collat_warning_2}</span>
                </span>
            </div>
        {/if}

        {#if +spread > 50}
            <div class="action-logs symbol-box text-xs font-semibold mr-0 w-fitrounded-3xl py-1 px-4 mt-3 md:mt-0 text-sky-500">
                <span>
                    <i class="fad fa-info-square text-lg text-green-400 pr-1"></i>
                    <span class="">{AddLiqTexts[$Locale].spread_limit_warning}{SPREAD_LIMIT / 100}%</span>
                </span>
            </div>
        {/if}

        {#if selectedVenues.some((venue) => paymentMethodsMap[venue].idInputFields.length > 0)}
            <div class="action-logs symbol-box text-xs flex flex-col font-semibold mr-0 w-fitrounded-3xl py-1 px-4 mt-3 md:mt-0 text-sky-500">
                {#each selectedVenues as venue}
                    {#if paymentMethodsMap[venue].idInputFields.length > 0}
                        <span>
                            <i class="fad fa-info-square text-lg text-green-400 pr-1"></i>
                            <span class="">{venue} {AddLiqTexts[$Locale].has_id_fields_msg} {paymentMethodsMap[venue].idInputFields}</span>
                        </span>
                    {/if}
                {/each}
            </div>
        {/if}
    </div>

    <!-- {#if $WalletConnected} -->
    <div class="addliq-button-container pt-4">
        <button class="addliq-button buttonGradient" on:click={onSubmitClick}>
            <div class={'text-white'}>{'Submit Liquidity'}</div>
        </button>
    </div>
    <!-- {/if} -->
</div>

{#if showTokenList}
    <TokenList
        searchAllChains={true}
        tokenList={tokens}
        showAvailableOn={false}
        showSearch={true}
        bind:selectedToken
        on:chainSwitched={updateNeededCollat}
        on:close={() => (showTokenList = false)} />
{/if}
{#if showCurrencyList}
    <TokenList tokenList={currencies} fiat={true} bind:selectedToken={selectedCurrency} on:close={() => (showCurrencyList = false)} />
{/if}

<style lang="scss">
    .addLiquidityRoot {
        @apply flex flex-col px-4 py-3 rounded-lg md:mx-4 font-medium text-sm h-full;
        background: var(--background-color);
    }

    .addliq-button-container {
        // position: relative;
        margin-top: 1rem;
        display: flex;
        flex-grow: 1;
    }
    .addliq-button {
        width: 90%;
        margin: auto;
        border-radius: 8px;
        padding: 0.75rem 0rem;
        margin-bottom: 0;
        position: relative;
        bottom: 1rem; /* Adjust this value to move the button up */
        // left: 50%;
        // transform: translateX(-50%);
        height: 3em;
        // padding: 0 1rem;
    }
    .remove-button {
        position: absolute;
        right: 0.5rem;
        background: none;
        border: none;
        cursor: pointer;
    }
    .kyp-input-container {
        position: relative;
    }
    :global(div.multiselect) {
        border: 0px;
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    :global(div.multiselect > svg) {
        display: none;
    }
    :global(div.multiselect > ul.options) {
        background: #181818;
        color: black;
        width: 8em;
    }
    :global(div.multiselect.open > ul.options) {
        max-height: 200px; /* Adjust the height as needed */
        overflow-y: auto;
        background-color: var(--input-bg);
        color: var(--button-text-label);
    }
</style>
